import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { appLog } from "../../../context";
import "./ErrorUnexpectedScreen.scss";

export default function ErrorUnexpectedScreen({
  message,
}: {
  message?: string;
}) {
  useEffect(() => {
    appLog.error(message);
    captureException(message);
  }, []);

  return (
    <div className="ErrorUnexpectedScreen">
      <h1>Oops!</h1>
      <p>
        Une erreur est survenue{" "}
        {message && (
          <>
            <br /> <small>{message}</small>
          </>
        )}
      </p>

      {/* NOTE: Don't use router here */}
      <a href="/" className="Btn lg primary">
        Retourner sur la page d'accueil
      </a>
    </div>
  );
}
