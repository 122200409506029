import { Dropdown } from "primereact/dropdown";
import type { SelectItem } from "primereact/selectitem";
import SlidersHIcon from "../../icons/SlidersHIcon";

import "./OurDropdown.scss";

export default function OurDropdown<T>({
  selectedOption,
  leftIcon,
  filter,
  placeholder,
  className,
  onChange,
  options,
}: {
  selectedOption?: T;
  filter?: boolean;
  leftIcon?: boolean;
  placeholder?: string;
  className?: string;
  onChange?(value: T): void;
  options: SelectItem[];
}) {
  return (
    <div className={`OurDropdown${leftIcon ? " withIcon" : ""}${className ? ` ${className}` : ""}`}>
      {leftIcon && (
        <div className="OurDropdown-icon">
          <SlidersHIcon />
        </div>
      )}
      <Dropdown
        className="OurDropdown-primereactDropdown"
        value={selectedOption}
        onChange={(e) => onChange?.(e.value)}
        options={options}
        checkmark={true}
        highlightOnSelect={false}
        filter={filter}
        placeholder={placeholder}
        emptyMessage="Pas de choix disponibles"
        emptyFilterMessage="Aucun résultat"
      />
    </div>
  );
}
