export default function CalendarIcon({ classNames }: { classNames?: string }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34375 2.5H10.4062V1.09375C10.4062 0.636719 10.7578 0.25 11.25 0.25C11.707 0.25 12.0938 0.636719 12.0938 1.09375V2.5H13.5C14.7305 2.5 15.75 3.51953 15.75 4.75V16C15.75 17.2656 14.7305 18.25 13.5 18.25H2.25C0.984375 18.25 0 17.2656 0 16V4.75C0 3.51953 0.984375 2.5 2.25 2.5H3.65625V1.09375C3.65625 0.636719 4.00781 0.25 4.5 0.25C4.95703 0.25 5.34375 0.636719 5.34375 1.09375V2.5ZM1.6875 16C1.6875 16.3164 1.93359 16.5625 2.25 16.5625H13.5C13.7812 16.5625 14.0625 16.3164 14.0625 16V7H1.6875V16Z"
        fill="black"
      />
    </svg>
  );
}
