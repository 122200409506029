import { nbVal } from "@paroi/data-formatters-lib";
import { useWrapAsync } from "@paroicms/front-app-log";
import { Button } from "primereact/button";
import { Suspense, lazy, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteDatabase,
  fetchDatabase,
  fetchSchemaFieldTypes,
  generateDatabase,
} from "../../../api-requests/databases";
import { appLog } from "../../../context";
import { syncOpenModalDialog } from "../../../global-tools/dialog-system";
import { showToast } from "../../../global-tools/toast-system";
import type { Database } from "../../../helpers/format-databases";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import EditIcon from "../../icons/EditIcon";
import TrashIcon from "../../icons/TrashIcon";
import Navbar from "../../parts/Navbar/Navbar";
import makeConfirmDialog from "../../utils/ConfirmDialog/ConfirmDialog";
import NoDataMessage from "../../utils/NoDataMessage/NoDataMessage";
import Spinner from "../../utils/Spinner/Spinner";

const SchemaFieldDataTable = lazy(
  () => import("../DatabaseCreateScreen/SchemaFieldDataTable/SchemaFieldDataTable"),
);

export default function DatabaseViewScreen() {
  const { databaseId } = useParams();
  const formatedDatabaseId = nbVal(databaseId);
  const wrapAsync = useWrapAsync();
  const setReferences = useAppStore((state) => state.setReferences);
  const [database, setDatabase] = useState<Database>();

  useEffect(
    wrapAsync(async () => {
      if (!database) {
        await apiRequestWrapper(
          async () => {
            setDatabase(await fetchDatabase(formatedDatabaseId));
          },
          { handle404: true },
        );
      }
    }),
    [database],
  );

  if (database === undefined) return <Spinner />;
  return (
    <div>
      <Navbar pageTitle={`Base de données: ${database.name}`} />

      <div className="DatabaseViewScreen Container">
        <Body
          formatedDatabaseId={formatedDatabaseId}
          database={database}
          onGenerateDatabase={() => {
            setDatabase(undefined);
            setReferences(undefined);
          }}
        />
      </div>
    </div>
  );
}

function Body({
  formatedDatabaseId,
  database,
  onGenerateDatabase,
}: {
  formatedDatabaseId: number;
  database: Database;
  onGenerateDatabase: () => void;
}) {
  const wrapAsync = useWrapAsync();
  const navigate = useNavigate();
  const [loadingDatabaseGeneration, setLoadingDatabaseGeneration] = useState(false);
  const setDatabases = useAppStore((state) => state.setDatabases);
  const setReferences = useAppStore((state) => state.setReferences);

  const handleDatabaseGeneration = wrapAsync(async () => {
    setLoadingDatabaseGeneration(true);
    try {
      await generateDatabase(formatedDatabaseId);
      onGenerateDatabase();
      showToast("Base de données générée", { severity: "success" });
    } catch (error) {
      appLog.error(error);
      showToast("Echec génération base de données", { severity: "error", sticky: true });
    }
    setLoadingDatabaseGeneration(false);
  });
  const handleDatabaseDeletion = async () => {
    try {
      await deleteDatabase(formatedDatabaseId);
      setDatabases(undefined);
      setReferences(undefined);
      navigate("/databases");
      showToast("Base de données supprimée", { severity: "success" });
    } catch (error) {
      appLog.error(error);
      showToast("Echec suppression base de données", { severity: "error", sticky: true });
    }
  };

  return (
    <div className="Container-content">
      <div className="flex">
        {!database.databaseGenerated && (
          <>
            <Link
              to={`/databases/${formatedDatabaseId}/edit`}
              className="Btn primary icon link mr-4"
            >
              Modifier <EditIcon width={17} height={18} />
            </Link>
            <Button
              className="Btn success icon mr-4"
              label="Activer"
              icon="pi pi-check"
              onClick={handleDatabaseGeneration}
              loading={loadingDatabaseGeneration}
            />
          </>
        )}

        <Button
          className="Btn danger icon"
          label="Supprimer"
          icon={<TrashIcon width={17} height={18} />}
          onClick={() =>
            syncOpenModalDialog(makeConfirmDialog, {
              onConfirm: handleDatabaseDeletion,
              warningMessage: (
                <>
                  Voulez-vous supprimer la base de données <b>{database.name}</b> ?
                </>
              ),
            })
          }
        />
      </div>

      {database.description && (
        <div
          className="Card ReferenceDescription"
          style={{ marginTop: "25px", marginBottom: "9px", padding: "14px 26px 16px 9px" }}
        >
          <h3 className="ReferenceDescription-title">Description du référentiel</h3>

          <p className="ReferenceDescription-content">{database.description}</p>
        </div>
      )}

      <SchemaFieldListWrapper database={database} />
    </div>
  );
}

function SchemaFieldListWrapper({
  database,
}: {
  database: Database;
}) {
  const wrapAsync = useWrapAsync();
  const schemaFieldTypes = useAppStore((state) => state.schemaFieldTypes);
  const setSchemaFieldTypes = useAppStore((state) => state.setSchemaFieldTypes);

  useEffect(
    wrapAsync(async () => {
      if (schemaFieldTypes !== undefined) return;
      await apiRequestWrapper(async () => {
        setSchemaFieldTypes(await fetchSchemaFieldTypes());
      });
    }),
    [schemaFieldTypes],
  );

  if (!schemaFieldTypes) return <Spinner />;
  if (schemaFieldTypes.size === 0) return <NoDataMessage message="Aucun type de champ défini" />;
  return (
    <Suspense fallback={<Spinner />}>
      <SchemaFieldDataTable
        className="mt-4"
        schemaFields={database.schema ?? []}
        schemaFieldTypes={schemaFieldTypes}
      />
    </Suspense>
  );
}
