import "primeflex/primeflex.css";
import "primeflex/themes/primeone-dark.css";
import "primeflex/themes/primeone-light.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./our-primereact.scss";
import "./theme/index.scss";

import { AppLogProvider } from "@paroicms/front-app-log";
import * as Sentry from "@sentry/react";
import { PrimeReactProvider } from "primereact/api";
import React from "react";
import ReactDOM from "react-dom/client";
import AppRoot from "./AppRoot.tsx";
import { appLog } from "./context.ts";
import { DialogSystem } from "./global-tools/dialog-system.tsx";
import { ToastSystem } from "./global-tools/toast-system.tsx";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://79a71dba0d6408754e47d3d252fb68ba@o4508133950226432.ingest.de.sentry.io/4508133957238864",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({})],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", "https://referentiel.xroad.bj"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  console.info("Sentry is disabled in development mode.");
}

const rootEl = document.getElementById("root");
if (!rootEl) throw new Error(`Missing '#root' element.`);

ReactDOM.createRoot(rootEl).render(
  <React.StrictMode>
    <AppLogProvider value={appLog}>
      <PrimeReactProvider>
        <AppRoot />
        <DialogSystem appLog={appLog} />
        <ToastSystem />
      </PrimeReactProvider>
    </AppLogProvider>
  </React.StrictMode>,
);
