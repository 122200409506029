import { nbVal } from "@paroi/data-formatters-lib";
import { BACKEND_API_URL, appLog } from "../context";
import { eventBus } from "../global-tools/event-bus";
import { globalSession } from "../global-tools/session-storage";

export interface FetchQueryResponse<T> {
  data: T;
  pagination?: Pagination;
}

export async function fetchQuery<T>(resource: string): Promise<FetchQueryResponse<T>>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    token: string;
  },
): Promise<FetchQueryResponse<T>>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    body?: string | FormData;
    method: "DELETE";
  },
): Promise<FetchQueryResponse<T> | undefined>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    body: string | FormData;
    method: "POST" | "PUT" | "PATCH";
  },
): Promise<FetchQueryResponse<T> | undefined>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    token?: string;
    body?: string | FormData;
    method?: "POST" | "PUT" | "PATCH" | "DELETE";
  } = {},
): Promise<FetchQueryResponse<T> | undefined> {
  let token = options.token;
  if (!token) token = globalSession?.token;
  const headers: HeadersInit = {
    Authorization: token ? `Bearer ${token}` : "",
  };
  if (!(options.body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  const response = await fetch(`${BACKEND_API_URL}/${resource}`, {
    method: options.method ?? "GET",
    headers,
    body: options.body,
  });
  if (response.status < 200 || response.status >= 300) {
    const remoteMessage = await response.text();
    throw new Error(`Error '${response.status}' from the server: ${remoteMessage}`);
  }

  try {
    const res = await response.json();
    let data = res.data;
    // TODO: Use request error interface to store this and use it for all requests
    if (!data && res.message) {
      data = {
        message: res.message,
        success: res.success,
      };
    } else if (!data && res.kpis) {
      data = {
        kpis: res.kpis,
        histogram_data: res.histogram_data,
      };
    }

    return {
      data,
      pagination: res.meta ? formatPagination(res.meta) : undefined,
    };
  } catch (error) {
    appLog.error("No json reponse body");
    if (options?.method) {
      return;
    }
    throw new Error("No json reponse body");
  }
}

export interface Pagination {
  perPage: number;
  total: number;
}

function formatPagination(data: any): Pagination {
  return {
    perPage: nbVal(data.per_page),
    total: nbVal(data.total),
  };
}

export async function apiRequestWrapper(
  request: () => Promise<void>,
  { handle404 }: { handle404?: boolean } = {},
): Promise<void> {
  try {
    await request();
  } catch (error) {
    appLog.error(error);
    if (handle404 && contains404(error)) {
      eventBus.emit("404");
      return;
    }
    eventBus.emit("fatalError", "Unexpected fetch error");
  }
}

export function contains404(error: unknown): boolean {
  if (!error) return false;
  if (typeof error === "string") return error.includes("404");
  if (typeof error === "object") {
    const err = error as any;
    return err?.message.includes("404");
  }
  return false;
}

export interface FetchFileInput {
  url: string;
  headers?: HeadersInit;
}

export async function fetchFile({
  headers,
  url,
}: FetchFileInput): Promise<{ blob: Blob; responseHeaders: Headers }> {
  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  if (response.status < 200 || response.status >= 300) {
    const remoteMessage = await response.text();
    throw new Error(`Error '${response.status}' from the server: ${remoteMessage}`);
  }

  return {
    blob: await response.blob(),
    responseHeaders: response.headers,
  };
}
