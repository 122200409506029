import { useWrapAsync } from "@paroicms/front-app-log";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { type KpiInputValues, addReferenceKpis } from "../../../../../api-requests/settings";
import { appLog } from "../../../../../context";
import { showToast } from "../../../../../global-tools/toast-system";
import type { Reference, SchemaField } from "../../../../../helpers/format-databases";
import NoDataMessage from "../../../../utils/NoDataMessage/NoDataMessage";
import Spinner from "../../../../utils/Spinner/Spinner";
import type { KpiInputValuesExtended } from "../DefineKpi";
import KpiCard from "../KpiCard/KpiCard";
import DefineChartKPI from "./DefineChartKPI/DefineChartKPI";

import "./DefineKpiContent.scss";

export default function DefineKpiContent({
  kpiSchemaFields,
  kpiInputValuesExtended,
  selectedReference,
  onSuccess,
  onKpiInputValuesChange,
  refreshKpiInputValues,
}: {
  selectedReference: Reference | undefined;
  kpiSchemaFields: SchemaField[] | undefined;
  kpiInputValuesExtended: KpiInputValuesExtended[] | undefined;
  onSuccess?(): void;
  onKpiInputValuesChange(kpiInputValuesExtended: KpiInputValuesExtended[]): void;
  refreshKpiInputValues(): void;
}) {
  const wrapAsync = useWrapAsync();
  const [loading, setLoading] = useState(false);
  const [chartKpiName, setChartKpiName] = useState("");
  const [chartKpiProperty, setChartKpiProperty] = useState<string>();

  const handleReferenceKpiAddition = wrapAsync(async () => {
    if (
      selectedReference === undefined ||
      !kpiInputValuesExtended ||
      kpiInputValuesExtended.length === 0 ||
      !chartKpiProperty
    ) {
      return;
    }
    const result = checkKPInputValues(kpiInputValuesExtended, selectedReference.schema);
    if (result.hasError) {
      showToast("Erreur définition de KPI", { severity: "warn" });
      onKpiInputValuesChange(result.newKpiInputValues);
      return;
    }
    setLoading(true);
    try {
      await addReferenceKpis(selectedReference.id, {
        kpis: kpiInputValuesExtended.map((k) => ({
          name: k.name,
          property: k.property,
          value: k.value,
        })),
        histogramKpi: {
          name: chartKpiName,
          property: chartKpiProperty,
        },
      });
      showToast("Mise à jour référentiel kpis réussie", {
        severity: "success",
      });
      onSuccess?.();
    } catch (error) {
      appLog.error(error);
      showToast("Echec mise à jour référentiel kpis", {
        severity: "error",
        sticky: true,
      });
    }
    setLoading(false);
  });
  const editKpi = (id: string, newVal: KpiInputValues) => {
    if (!kpiInputValuesExtended) return;
    const index = kpiInputValuesExtended.findIndex((k) => k.id === id);
    if (index === -1) return;
    kpiInputValuesExtended[index] = {
      ...newVal,
      id,
    };
    onKpiInputValuesChange(kpiInputValuesExtended);
  };

  useEffect(() => {
    if (!selectedReference) return;
    const validFields = selectedReference.schema.filter((f) => f.type !== "file");
    setChartKpiProperty(validFields[0].name);
  }, [selectedReference]);

  if (selectedReference === undefined) {
    return (
      <div className="mt-6">
        <NoDataMessage message="Aucun référentiel sélectionné" />
      </div>
    );
  }
  if (!kpiSchemaFields || !kpiInputValuesExtended) return <Spinner />;
  if (kpiSchemaFields.length === 0) {
    return (
      <div className="mt-6">
        <NoDataMessage message="Aucun champ valide pour les KPI" />
      </div>
    );
  }
  return (
    <div className="DefineKpiContent mt-4">
      <div className="flex align-content-end">
        <Button
          icon="pi pi-refresh"
          rounded
          text
          className="p-outlined-primary IconOnlyBtn"
          onClick={refreshKpiInputValues}
        />
      </div>
      {kpiInputValuesExtended.length > 0 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleReferenceKpiAddition();
          }}
        >
          <div className="DefineKpiContent-cardList">
            {kpiInputValuesExtended.map((kpi) => (
              <KpiCard key={kpi.id} kpi={kpi} kpiSchemaFields={kpiSchemaFields} editKpi={editKpi} />
            ))}
          </div>

          <DefineChartKPI
            name={chartKpiName}
            property={chartKpiProperty}
            kpiSchemaFields={kpiSchemaFields}
            onNameChange={(val) => setChartKpiName(val)}
            onPropertyChange={(val) => setChartKpiProperty(val)}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className="mt-6"
          >
            <Button
              type="submit"
              className="Btn lg primary"
              label="Valider"
              loading={loading}
              disabled={selectedReference === undefined || kpiInputValuesExtended.length === 0}
            />
          </div>
        </form>
      )}
    </div>
  );
}

function checkKPInputValues(
  kpiInputValues: KpiInputValuesExtended[],
  schema: SchemaField[],
): {
  newKpiInputValues: KpiInputValuesExtended[];
  hasError: boolean;
} {
  let hasError = false;
  const newKpiInputValues = kpiInputValues.map((v) => {
    const foundField = schema.find((f) => f.name === v.property);
    if (!foundField) {
      throw new Error("Schema field not found");
    }
    if (foundField.required && v.value === null) {
      hasError = true;
      return {
        ...v,
        error: "Valeur requise",
      };
    }
    return v;
  });
  return {
    newKpiInputValues,
    hasError,
  };
}
