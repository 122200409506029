import { listVal } from "@paroi/data-formatters-lib";
import { type User, formatUser } from "../helpers/format-users";
import { fetchQuery } from "../helpers/http-client";
import type { ApiRequestResponse } from "./reference-data";

export async function initialFetchAuthUser(token: string): Promise<User> {
  const res = await fetchQuery("me", {
    token,
  });
  return formatUser(res.data);
}

export async function fetchAuthUser(): Promise<User> {
  const res = await fetchQuery("me");
  return formatUser(res.data);
}

export async function fetchUsers(): Promise<ApiRequestResponse<User[]>> {
  const { data, pagination } = await fetchQuery("users");
  return {
    data: listVal(data, formatUser),
    pagination,
  };
}

export interface UserFormInput {
  lastname: string;
  firstname: string;
  email: string;
  roleId: number;
  address?: string;
  phone: string;
  password: string;
}

export async function createUser(body: UserFormInput): Promise<void> {
  await fetchQuery("users", {
    method: "POST",
    body: JSON.stringify({
      ...body,
      role_id: body.roleId,
    }),
  });
}

export async function deleteUser(id: number): Promise<void> {
  await fetchQuery(`users/${id}`, {
    method: "DELETE",
  });
}
