import { InputText } from "primereact/inputtext";
import { useState } from "react";
import type { SchemaField } from "../../../helpers/format-databases";

export default function TextInput({
  label,
  value,
  field,
  onChange,
  onError,
}: {
  label?: string;
  value: string;
  field: SchemaField;
  onChange: (value: string) => void;
  onError: (value: boolean) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string>();

  const onValueChange = (val: string) => {
    if (field.required && val === "") {
      setErrorMessage("Ce champ est requis.");
      onError(true);
    } else if (field.length && val.length > field.length) {
      setErrorMessage(`Ce champ doit avoir longueur maximum de ${field.length}.`);
      onError(true);
    } else {
      setErrorMessage(undefined);
      onError(false);
    }
  };

  // TODO: improve better input error handling
  // useEffect(() => {
  //   onValueChange(value);
  // }, []);

  return (
    <label className="Field">
      <span className="Field-label">
        {label ?? field.label}{" "}
        {field.required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
      </span>
      <InputText
        className="Field-input w-full"
        value={value}
        onChange={(ev) => {
          const val = ev.target.value;
          onChange(val);
          onValueChange(val);
        }}
        invalid={errorMessage !== undefined}
        required={field.required}
      />
      {errorMessage && <small className="Field-errorMessage">{errorMessage}</small>}
    </label>
  );
}
