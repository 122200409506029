import { useWrapAsync } from "@paroicms/front-app-log";
import { Suspense, lazy, useEffect, useState } from "react";
import { fetchDatabase } from "../../../api-requests/databases";
import { fetchSettings } from "../../../api-requests/settings";
import { type RequestError, fetchDashboardStatistics } from "../../../api-requests/statistics";
import { appLog } from "../../../context";
import type { Database } from "../../../helpers/format-databases";
import type { Statistics } from "../../../helpers/format-statistics";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import Navbar from "../../parts/Navbar/Navbar";
import NoDataMessage from "../../utils/NoDataMessage/NoDataMessage";
import Spinner from "../../utils/Spinner/Spinner";

const StatisticsPart = lazy(() => import("../StatisticsScreen/StatisticsPart/StatisticsPart"));

export default function HomeScreen() {
  const wrapAsync = useWrapAsync();
  const settings = useAppStore((state) => state.settings);
  const setSettings = useAppStore((state) => state.setSettings);
  const [database, setDatabase] = useState<Database>();
  const [statistics, setStatistics] = useState<Statistics | RequestError>();

  useEffect(
    wrapAsync(async () => {
      if (settings) return;
      await apiRequestWrapper(
        async () => {
          setSettings(await fetchSettings());
        },
        { handle404: true },
      );
    }),
    [settings],
  );
  useEffect(
    wrapAsync(async () => {
      const defaultReferenceId = settings?.defaultReferenceId;
      if (!defaultReferenceId) return;
      await apiRequestWrapper(
        async () => {
          setDatabase(await fetchDatabase(defaultReferenceId));
        },
        { handle404: true },
      );
    }),
    [settings],
  );
  useEffect(
    wrapAsync(async () => {
      try {
        setStatistics(await fetchDashboardStatistics());
      } catch (error) {
        appLog.error(error);
        setStatistics({
          error: "An error occurs when trying to fetch statistics",
        });
      }
    }),
    [],
  );

  if (!settings || !database || !statistics) return <Spinner />;
  if (!settings.defaultReferenceId) {
    return <NoDataMessage message="Aucun référentiel par défaut défini" />;
  }
  return (
    <div>
      <Navbar pageTitle="" />

      <div className="Container">
        <div className="Container-content">
          <Suspense fallback={<Spinner />}>
            <StatisticsPart statistics={statistics} referenceId={settings.defaultReferenceId} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
