import type { ReferenceDatumInput } from "../api-requests/reference-data";
import type { SchemaField } from "./format-databases";

export function transformToFormDataIfFileField(
  inputValues: ReferenceDatumInput,
  schema: SchemaField[],
): ReferenceDatumInput | FormData {
  const foundFileType = schema.find((f) => f.type === "file");
  if (foundFileType) {
    const formData = new FormData();
    for (const [key, value] of inputValues) {
      if (value !== undefined) {
        formData.append(key, value instanceof File ? value : `${value}`);
      }
    }
    return formData;
  }
  return inputValues;
}
