export default function EditIcon({
  classNames,
  width = 23,
  height = 24,
}: { classNames?: string; width?: number; height?: number }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0117 2.7168C23 3.70508 23 5.27734 22.0117 6.26562L20.6641 7.61328L16.2617 3.21094L17.6094 1.86328C18.5977 0.875 20.1699 0.875 21.1582 1.86328L22.0117 2.7168ZM7.72656 11.7461L15.2285 4.24414L19.6309 8.64648L12.1289 16.1484C11.8594 16.418 11.5 16.6426 11.1406 16.7773L7.14258 18.0801C6.73828 18.2148 6.33398 18.125 6.06445 17.8105C5.75 17.541 5.66016 17.0918 5.79492 16.7324L7.09766 12.7344C7.23242 12.375 7.45703 12.0156 7.72656 11.7461ZM8.625 3.75C9.38867 3.75 10.0625 4.42383 10.0625 5.1875C10.0625 5.99609 9.38867 6.625 8.625 6.625H4.3125C3.50391 6.625 2.875 7.29883 2.875 8.0625V19.5625C2.875 20.3711 3.50391 21 4.3125 21H15.8125C16.5762 21 17.25 20.3711 17.25 19.5625V15.25C17.25 14.4863 17.8789 13.8125 18.6875 13.8125C19.4512 13.8125 20.125 14.4863 20.125 15.25V19.5625C20.125 21.9434 18.1934 23.875 15.8125 23.875H4.3125C1.88672 23.875 0 21.9434 0 19.5625V8.0625C0 5.68164 1.88672 3.75 4.3125 3.75H8.625Z"
        fill="#1F4878"
      />
    </svg>
  );
}
