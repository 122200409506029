import { Button } from "primereact/button";
import { useRef, useState } from "react";
import {
  type ReferenceDatumInput,
  type ReferenceDatumInputVal,
  createReferenceDatum,
} from "../../../api-requests/reference-data";
import { appLog } from "../../../context";
import { showToast } from "../../../global-tools/toast-system";
import type { SchemaField } from "../../../helpers/format-databases";
import { transformToFormDataIfFileField } from "../../../helpers/transform-to-form-data";
import { useAppStore } from "../../../store/app.store";
import type { FileInputHandle } from "../../utils/SchemaFieldTypeInputs/FileInput/FileInput";
import SchemaFieldInput from "../../utils/SchemaFieldTypeInputs/SchemaFieldInput";

export default function CreateReferenceDatum({
  referenceId,
  referenceSchema,
  className,
}: {
  referenceId: number;
  referenceSchema: SchemaField[];
  className?: string;
}) {
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState<ReferenceDatumInput>(new Map());
  const [errorStatus, setErrorStatus] = useState<Map<string, boolean>>(new Map());
  const referenceDataById = useAppStore((state) => state.referenceDataById);
  const setReferenceDataById = useAppStore((state) => state.setReferenceDataById);
  const setReferences = useAppStore((state) => state.setReferences);
  const fileInputRef = useRef<FileInputHandle>(null);

  const onChange = ({ name, value }: { name: string; value?: ReferenceDatumInputVal }) => {
    const oldValues: ReferenceDatumInput = new Map(inputValues);
    oldValues.set(name, value);
    setInputValues(oldValues);
  };
  const handleReferenceDatumCreation = async () => {
    if (fileInputRef.current && !fileInputRef.current.checkInput()) return;
    if (inputValues.size === 0) return;
    setLoading(true);
    const values = transformToFormDataIfFileField(inputValues, referenceSchema);
    try {
      await createReferenceDatum(referenceId, values);
      setInputValues(new Map());
      showToast("Donnée ajoutée", { severity: "success" });
      const oldReferenceDataById = new Map(referenceDataById);
      oldReferenceDataById.set(referenceId, undefined);
      setReferenceDataById(oldReferenceDataById);
      setReferences(undefined);
    } catch (error) {
      appLog.error(error);
      showToast("Echec ajout de donnée", { severity: "error", sticky: true });
    }
    setLoading(false);
  };
  const disabledButton = () => {
    for (const [, status] of errorStatus) {
      if (status) {
        return true;
      }
    }
    return false;
  };

  return (
    <form
      className={`Card${className ? ` ${className}` : ""}`}
      style={{
        textAlign: "left",
        padding: "30px 21px 25px 25px",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        handleReferenceDatumCreation();
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "25px",
        }}
      >
        {referenceSchema.map((field) => (
          <SchemaFieldInput
            key={field.name}
            field={field}
            value={inputValues.get(field.name)}
            forwardedRef={fileInputRef}
            onChange={onChange}
            onError={(val) => {
              const oldErrorStatus = new Map(errorStatus);
              oldErrorStatus.set(field.name, val);
              setErrorStatus(oldErrorStatus);
            }}
          />
        ))}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "23.5px",
          marginTop: "32px",
        }}
      >
        <Button
          type="submit"
          className="Btn primary"
          label="Enregistrer"
          disabled={disabledButton()}
          loading={loading}
        />
        <span style={{ color: "#BABABA" }}>ou</span>
        <Button className="Btn success icon" label="Uploader un fichié" icon="pi pi-save" />
      </div>
    </form>
  );
}
