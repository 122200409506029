import { listVal, nbVal, strVal, strValOrUndef } from "@paroi/data-formatters-lib";

export interface Role {
  id: number;
  description?: string;
  name: string;
  permissions: Permission[];
}

export function formatRole(data: any): Role {
  return {
    id: nbVal(data.id),
    description: strValOrUndef(data.description),
    name: strVal(data.name),
    permissions: listVal(data.permissions, formatPermission),
  };
}

export interface Permission {
  id: number;
  description?: string;
  name: string;
  label: string;
}

function formatPermission(data: any): Permission {
  return {
    id: nbVal(data.id),
    label: strVal(data.id),
    name: strVal(data.id),
    description: strValOrUndef(data.id),
  };
}
