import { Button } from "primereact/button";
import {
  FileUpload,
  type FileUploadHandlerEvent,
  type FileUploadHeaderTemplateOptions,
  type FileUploadOptions,
} from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { type Ref, useImperativeHandle, useRef, useState } from "react";
import { showToast } from "../../../../global-tools/toast-system";
import { createAcceptTypeFilter } from "../../../../helpers/accept-type-filter";
import type { SchemaField } from "../../../../helpers/format-databases";
import "./FileInput.scss";

export interface FileInputProps {
  currentFile?: File;
  field: SchemaField;
  label?: string;
  onChange: (value?: File) => void;
  onError: (value: boolean) => void;
  forwardedRef: Ref<FileInputHandle>;
}

export interface FileInputHandle {
  checkInput: () => boolean;
}

export function FileInput({
  field,
  onChange,
  onError,
  currentFile,
  label,
  forwardedRef,
}: FileInputProps) {
  const limitSize = 10_000_000; // 10 Mo
  const accept = "image/*,text/csv,application/pdf";
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onFileChange = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];
    validateInputValue(file);
    const acceptTypeFilter = createAcceptTypeFilter(accept);
    if (!acceptTypeFilter(file.type)) {
      fileUploadRef.current?.clear();
      showToast("Fichier invalide", {
        severity: "warn",
      });
      return;
    }
    setTotalSize(file.size);
    onChange(file);
    showToast("Fichier uploadé", { severity: "info" });
  };
  const onValidationFail = (file: File) => {
    const hasExceededSizeLimit = file.size > totalSize;
    const message = hasExceededSizeLimit ? "Fichier trop volumineux" : "Une erreur est survenue";
    showToast(message, { severity: hasExceededSizeLimit ? "warn" : "error" });
  };
  const onTemplateClear = () => {
    setTotalSize(0);
    onChange(undefined);
    validateInputValue(undefined);
  };
  const validateInputValue = (fileInputValue?: File): boolean => {
    if (field.required && !fileInputValue) {
      setErrorMessage("Ce champ est requis.");
      onError(true);
      return false;
    }

    setErrorMessage(undefined);
    onError(false);
    return true;
  };

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        checkInput: () => validateInputValue(currentFile),
      };
    },
    [currentFile],
  );

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;
    const progressBarVal = (totalSize * 100) / limitSize;
    const formatedValue = fileUploadRef.current
      ? fileUploadRef.current.formatSize(totalSize)
      : "0 B";

    return (
      <div
        className={`FileInput-headerTemplate ${className}`}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          padding: "4.5px 10px",
          borderBottom: "1px solid #e5e7eb",
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
        }}
      >
        {chooseButton}
        <div className="flex gap-3" style={{ padding: "0" }}>
          <div>
            <div className="mb-1">
              <span>
                {formatedValue} / {limitSize / 1_000_000} Mo
              </span>
              {currentFile && <span className="ml-2">{currentFile.name}</span>}
            </div>
            <ProgressBar
              value={progressBarVal}
              showValue={false}
              style={{ width: "10rem", height: "12px" }}
            />
          </div>

          {currentFile && (
            <Button
              icon="pi pi-times"
              rounded
              outlined
              severity="danger"
              onClick={() => fileUploadRef.current?.clear()}
              style={{ width: "30px", height: "5px", margin: "0" }}
            />
          )}
        </div>
      </div>
    );
  };
  const chooseOptions: FileUploadOptions = {
    icon: "pi pi-fw pi-images",
    className: "custom-choose-btn p-button-rounded p-button-outlined",
    style: { padding: "6px" },
  };

  return (
    <div className="Field">
      <span className="Field-label">
        {label ?? field.label}{" "}
        {field.required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
      </span>
      <FileUpload
        ref={fileUploadRef}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        onValidationFail={onValidationFail}
        auto
        chooseOptions={chooseOptions}
        accept={accept}
        maxFileSize={limitSize}
        customUpload
        uploadHandler={onFileChange}
        chooseLabel="Choisir un fichier"
        contentStyle={{ display: "none" }}
      />
      {errorMessage && <small className="Field-errorMessage">{errorMessage}</small>}
    </div>
  );
}
