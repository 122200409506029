import { Link } from "react-router-dom";

import { Badge } from "primereact/badge";
import "./DatabaseCard.scss";

export default function DatabaseCard({
  route,
  label,
  fields,
  rows,
  date,
  status,
}: {
  route: string;
  date: string;
  label: string;
  fields: number;
  rows: number;
  status: "in-progress" | "validate";
}) {
  return (
    <Link to={route} className="DatabaseCard">
      <div className="DatabaseCard-header">
        <span className="DatabaseCard-title">{label}</span>
        <span className="DatabaseCard-headerRight">
          <span className="DatabaseCard-headerRightText">{rows}</span>{" "}
          <small className="DatabaseCard-headerRightSmallText">Ligne{rows > 1 ? "s" : ""}</small>
        </span>
      </div>
      <div className="DatabaseCard-content">
        <div className="DatabaseCard-left">
          <p className="DatabaseCard-fields">
            <b>{fields}</b> <small className="DatabaseCard-smallText">Champs</small>
          </p>
          <p className="DatabaseCard-date">
            Créée le: <b>{date}</b>
          </p>
        </div>
        <Badge
          className="DatabaseCard-badge"
          value={status === "validate" ? "Validée" : "En attente"}
          severity={status === "validate" ? "success" : "warning"}
        />
      </div>
    </Link>
  );
}
