export default function DotCircleIcon({ classNames }: { classNames?: string }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.25C0 4.29297 4.00781 0.25 9 0.25C13.957 0.25 18 4.29297 18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25ZM9 12.625C10.8633 12.625 12.375 11.1133 12.375 9.25C12.375 7.38672 10.8633 5.875 9 5.875C7.10156 5.875 5.625 7.38672 5.625 9.25C5.625 11.1133 7.10156 12.625 9 12.625Z"
        fill="black"
      />
    </svg>
  );
}
