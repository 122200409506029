import { type HistogramKpi, type Settings, formatSettings } from "../helpers/format-settings";
import { fetchQuery } from "../helpers/http-client";

export async function fetchSettings(): Promise<Settings> {
  const { data } = await fetchQuery("settings");
  return formatSettings(data);
}

export async function updateDefaultReference(id: number): Promise<void> {
  await fetchQuery("settings/default-reference", {
    method: "PUT",
    body: JSON.stringify({
      default_reference_id: id,
    }),
  });
}

export interface AddReferenceKpisInputValues {
  kpis: KpiInputValues[];
  histogramKpi: HistogramKpi;
}

export interface KpiInputValues {
  name: string | null;
  property: string;
  value: string | boolean | null;
}

export async function addReferenceKpis(
  referenceId: number,
  { kpis, histogramKpi }: AddReferenceKpisInputValues,
): Promise<void> {
  await fetchQuery("settings/reference-kpis", {
    method: "PUT",
    body: JSON.stringify({
      reference_id: referenceId,
      kpis: kpis.map((k) => ({ porperty: k.property, value: k.value, name: k.name })),
      histogram_kpi: {
        name: histogramKpi.name,
        porperty: histogramKpi.property,
      },
    }),
  });
}
