import { promiseToHandle } from "@paroi/async-lib";
import { useWrapAsync } from "@paroicms/front-app-log";
import { Dialog } from "primereact/dialog";
import { useReducer, useState } from "react";

export interface DialogSystemAppLog {
  error: (message: any) => void;
  warn: (message: string) => void;
}

export type DialogFn<R, P> = (props: ModalDialogProps<R, P>) => {
  content?: JSX.Element;
  footer?: JSX.Element;
  header?: string | JSX.Element;
};

export interface ModalDialogProps<R = unknown, P = unknown> {
  closeDialog: (returnValue?: R) => void;
  /**
   * @param listener a callback that can return `false` to prevent closing.
   */
  onCancelDialog: (listener: () => boolean | undefined | Promise<boolean | undefined>) => void;
  parameter: P;
}

interface DialogStackItem {
  id: number;
  resolve: (result: unknown) => void;
  reject: (error: unknown) => void;
  dialog: DialogFn<any, any>;
  parameter?: any;
}

let idSeq = 0;
const dialogStack: DialogStackItem[] = [];
let forceRepaint: () => void;
let appLog: DialogSystemAppLog;

export function openModalDialog<R>(dialog: DialogFn<R, undefined>): Promise<R | undefined>;
export function openModalDialog<R, P>(dialog: DialogFn<R, P>, parameter: P): Promise<R | undefined>;
export function openModalDialog<R, P>(
  dialog: DialogFn<R, P>,
  parameter?: P,
): Promise<R | undefined> {
  const { promise, resolve, reject } = promiseToHandle<any>();
  dialogStack.push({
    id: ++idSeq,
    resolve,
    reject,
    dialog,
    parameter,
  });
  forceRepaint();
  return promise;
}

export function syncOpenModalDialog(dialog: DialogFn<void, undefined>): void;
export function syncOpenModalDialog<P>(dialog: DialogFn<void, P>, parameter: P): void;
export function syncOpenModalDialog(dialog: DialogFn<void, any>, parameter?: any): void {
  openModalDialog(dialog, parameter).catch(appLog.error);
}

export function DialogSystem(props: { appLog: DialogSystemAppLog }) {
  const [, dispatch] = useReducer((x) => x + 1, 0);
  forceRepaint = dispatch;
  appLog = props.appLog;

  return (
    <>
      {dialogStack.map((item) => {
        return <ModalDialog key={item.id} forceRepaint={dispatch} item={item} />;
      })}
    </>
  );
}

function ModalDialog({
  forceRepaint,
  item,
}: {
  forceRepaint: () => void;
  item: DialogStackItem;
}) {
  const [opened, setOpened] = useState(true);
  const wrapAsync = useWrapAsync();
  let [cancelListener] = useState<() => boolean | undefined | Promise<boolean | undefined>>();

  const dialogProps: ModalDialogProps<any, any> = {
    closeDialog: (returnValue) => {
      setOpened(false);
      return closeDialog(returnValue, item, forceRepaint);
    },
    onCancelDialog: (listener) => {
      cancelListener = listener; // do not trigger a rendering
    },
    parameter: item.parameter,
  };

  const cancel = wrapAsync(async () => {
    if ((await cancelListener?.()) !== false) {
      dialogProps.closeDialog();
    }
  });

  const { content, footer, header } = item.dialog(dialogProps);

  return (
    <Dialog key={item.id} visible={opened} onHide={cancel} header={header} footer={footer}>
      {content}
    </Dialog>
  );
}

export interface DialogWrapProps {
  header: string;
  footer: JSX.Element;
  children: JSX.Element;
}

function closeDialog(returnValue: unknown, item: DialogStackItem, forceRepaint: () => void) {
  const index = dialogStack.findIndex(({ id }) => id === item.id);
  if (index === -1) {
    appLog.warn(`Missing dialog '${item.id}'`);
    return;
  }

  // close child dialogs
  for (let i = dialogStack.length - 1; i > index; --i) {
    dialogStack[i].resolve(undefined);
  }

  dialogStack.splice(index);
  forceRepaint();
  item.resolve(returnValue);
}
