import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import type { SelectItem } from "primereact/selectitem";
import { useEffect, useRef, useState } from "react";
import type { ReferenceDatumInputVal } from "../../../../api-requests/reference-data";
import type { KpiInputValues } from "../../../../api-requests/settings";
import type { ModalDialogProps } from "../../../../global-tools/dialog-system";
import type { SchemaField } from "../../../../helpers/format-databases";
import OurDropdown from "../../../utils/OurDropdown/OurDropdown";
import type { FileInputHandle } from "../../../utils/SchemaFieldTypeInputs/FileInput/FileInput";
import SchemaFieldInput from "../../../utils/SchemaFieldTypeInputs/SchemaFieldInput";
import Spinner from "../../../utils/Spinner/Spinner";
import type { KpiInputValuesExtended } from "./DefineKpi";

export interface DefineKpiFormDialogProps extends ModalDialogProps<void> {
  closeDialog: () => void;
  parameter: DefineKpiFormDialogParameter;
}

export interface DefineKpiFormDialogParameter {
  title: string;
  kpiToEdit: KpiInputValuesExtended;
  kpiSchemaFields: SchemaField[];
  save(id: string, newVal: KpiInputValues): void;
}

export interface KpiPropertyOption {
  value: string;
  label: string;
}

export default function makeDefineKpiFormDialog({
  closeDialog,
  parameter: { title, kpiToEdit, kpiSchemaFields, save },
}: DefineKpiFormDialogProps) {
  const [label, setLabel] = useState("");
  const [property, setProperty] = useState("");
  const [value, setValue] = useState<ReferenceDatumInputVal>();
  const [field, setField] = useState<SchemaField>();
  const [nameErrorMessage, setNameErrorMessage] = useState<string>();
  const [errorStatus, setErrorStatus] = useState<Map<string, boolean>>(new Map());
  const fileInputRef = useRef<FileInputHandle>(null);

  const handleSave = () => {
    if (fileInputRef.current && !fileInputRef.current.checkInput()) return;
    save(kpiToEdit.id, {
      name: label === "" ? null : label,
      property,
      value: value === undefined ? null : `${value}`,
    });
    closeDialog();
  };
  const onNameChange = (value: string) => {
    if (value === "") {
      setNameErrorMessage("Ce champ est requis");
    } else {
      setNameErrorMessage(undefined);
    }
  };
  const onPropertyChange = (value: string) => {
    setProperty(value);
    setValue(undefined);
    const currentField = kpiSchemaFields.find((f) => f.name === value);
    if (!currentField) {
      throw new Error("No found schema field");
    }
    setField(currentField);
  };
  const disabledButton = () => {
    for (const [, status] of errorStatus) {
      if (status || nameErrorMessage !== undefined) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (kpiToEdit.name) setLabel(kpiToEdit.name);
    if (kpiToEdit.value) setValue(kpiToEdit.value);
    if (kpiPropertyOptions.length === 0) throw new Error("No schema field");
    setProperty(kpiToEdit.property ?? kpiPropertyOptions[0].value);
    const currentField = kpiSchemaFields.find((f) => f.name === kpiToEdit.property);
    if (!currentField) {
      throw new Error("No found schema field");
    }
    setField(currentField);
  }, [kpiToEdit]);

  const kpiPropertyOptions = kpiSchemaFields.map<KpiPropertyOption>((k) => ({
    label: k.label,
    value: k.name,
  }));

  const content = !field ? (
    <Spinner />
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
    >
      <label className="Field mb-4">
        <span className="Field-label">Libellé KPI</span>
        <InputText
          className="Field-input w-full"
          placeholder="Champ requis *"
          value={label}
          onChange={(e) => {
            const val = e.target.value;
            setLabel(val);
            onNameChange(val);
          }}
          invalid={nameErrorMessage !== undefined}
          required
        />
        {nameErrorMessage && <small className="Field-errorMessage">{nameErrorMessage}</small>}
      </label>

      <label className="Field mb-4">
        <span className="Field-label">
          Propriété KPI<i style={{ color: "red", fontWeight: "bold" }}>*</i>
        </span>
        <OurDropdown<string>
          selectedOption={property}
          options={kpiPropertyOptions.map<SelectItem>((o) => ({
            label: o.label,
            value: o.value,
          }))}
          onChange={(e) => onPropertyChange(e)}
        />
      </label>

      <SchemaFieldInput
        key={field.name}
        label="Valeur KPI"
        field={field}
        value={value}
        forwardedRef={fileInputRef}
        onChange={(val) => setValue(val.value)}
        onError={(val) => {
          const oldErrorStatus = new Map(errorStatus);
          oldErrorStatus.set(kpiToEdit.property, val);
          setErrorStatus(oldErrorStatus);
        }}
      />

      <div className="flex justify-content-between gap-3 mt-6">
        <Button className="Btn secondary" label="Annuler" onClick={closeDialog} />
        <Button className="Btn primary" label="Valider" disabled={disabledButton()} />
      </div>
    </form>
  );

  return {
    header: title,
    content,
  };
}
