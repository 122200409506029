import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import type { SchemaFieldOption } from "../../../../helpers/format-databases";
import TrashIcon from "../../../icons/TrashIcon";

export default function MultipleChoiceOptions({
  options,
  onChange,
}: {
  options?: SchemaFieldOption[];
  onChange: (options: SchemaFieldOption[]) => void;
}) {
  const [errors, setErrors] = useState<Map<string, string>>(new Map());
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");

  const deleteOption = (key: string) => {
    if (!options) return;
    const index = options.findIndex((o) => o.key === key);
    if (index !== -1) {
      options.splice(index, 1);
    }
    onChange([...options]);
  };
  const addOption = () => {
    if (key === "" || value === "") return;
    onChange([
      ...(options ?? []),
      {
        key,
        value,
      },
    ]);
    setKey("");
    setValue("");
  };
  const validateKeyInput = (key: string) => {
    const oldErrors = new Map(errors);
    const foundOption = options?.find((o) => o.key === key);
    if (key === "") {
      oldErrors.set("key", "Ce champ est requis");
    } else if (foundOption) {
      oldErrors.set("key", "Cette clé existe déjà");
    } else {
      oldErrors.delete("key");
    }
    setErrors(oldErrors);
  };
  const validateValueInput = (value: string) => {
    const oldErrors = new Map(errors);
    if (value === "") {
      oldErrors.set("value", "Ce champ est requis");
    } else {
      oldErrors.delete("value");
    }
    setErrors(oldErrors);
  };

  return (
    <div className="DatabaseConfig-multipleChoice">
      <ul style={{ listStyle: "none", margin: "0", padding: "0" }}>
        {options?.map((option) => (
          <li key={option.key} style={{ display: "flex", alignItems: "baseline" }}>
            <span className="mr-4">
              <b>Clé:</b> {option.key}
            </span>
            <span>
              <b>Valeur:</b> {option.value}
            </span>
            <Button
              className="IconOnlyBtn ml-4"
              icon={<TrashIcon />}
              severity="danger"
              rounded
              text
              onClick={() => deleteOption(option.key)}
            />
          </li>
        ))}
      </ul>

      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", marginBottom: "20px", gap: "20px" }}>
          <label className="Field">
            <span className="Field-label">Clé</span>
            <InputText
              style={{ fontSize: "14px" }}
              className="Field-input"
              value={key}
              onChange={(ev) => {
                const val = ev.target.value.toLowerCase();
                setKey(val);
                validateKeyInput(val);
              }}
              invalid={!!errors.get("key")}
            />
            {!!errors.get("key") && (
              <small className="Field-errorMessage">{errors.get("key")}</small>
            )}
          </label>

          <label className="Field">
            <span className="Field-label">Valeur</span>
            <InputText
              style={{ fontSize: "14px" }}
              className="Field-input"
              value={value}
              onChange={(ev) => {
                const val = ev.target.value;
                setValue(val);
                validateValueInput(val);
              }}
              invalid={!!errors.get("value")}
            />
            {!!errors.get("value") && (
              <small className="Field-errorMessage">{errors.get("value")}</small>
            )}
          </label>
        </div>

        <Button
          className="Btn sm primary"
          onClick={(ev) => {
            ev.preventDefault();
            addOption();
          }}
          disabled={errors.size > 0 || key === "" || value === ""}
        >
          Ajouter
        </Button>
      </div>
    </div>
  );
}
