import AsinLogo from "../../assets/images/asin-logo.svg";
import DatabaseManagement from "../../assets/images/database-management.svg";
import GovLogo from "../../assets/images/gov-logo.png";
import { LOGIN_URL } from "../../context";
import "./LoginScreen.scss";

export default function LoginScreen() {
  return (
    <div className="LoginScreen Middle">
      <div className="LoginScreen-body">
        <img className="LoginScreen-govLogo" src={GovLogo} alt="" />

        <div className="Card LoginScreen-card">
          <p className="LoginScreen-cardTitle">
            Connectez-vous pour gérer <br /> vos bases de données et <br /> accéder à nos outils{" "}
            <br /> puissants
          </p>
          <a className="Btn lg primary" href={LOGIN_URL} target="_blank" rel="noreferrer">
            Se connecter
          </a>
        </div>

        <img className="LoginScreen-asinLogo" src={AsinLogo} alt="" />
      </div>
      <img className="LoginScreen-bottomImg" src={DatabaseManagement} alt="" />
    </div>
  );
}
