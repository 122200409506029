export default function TrashIcon({
  classNames,
  width = 21,
  height = 24,
}: { classNames?: string; width?: number; height?: number }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width={width}
      height={height}
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50195 1.68359C6.72656 1.18945 7.2207 0.875 7.75977 0.875H13.1953C13.7344 0.875 14.2285 1.18945 14.4531 1.68359L14.8125 2.3125H19.125C19.8887 2.3125 20.5625 2.98633 20.5625 3.75C20.5625 4.55859 19.8887 5.1875 19.125 5.1875H1.875C1.06641 5.1875 0.4375 4.55859 0.4375 3.75C0.4375 2.98633 1.06641 2.3125 1.875 2.3125H6.1875L6.50195 1.68359ZM18.1367 21.8535C18.0918 23.0215 17.1484 23.875 15.9805 23.875H4.97461C3.80664 23.875 2.86328 23.0215 2.81836 21.8535L1.83008 6.625H19.125L18.1367 21.8535Z"
        fill="currentColor"
      />
    </svg>
  );
}
