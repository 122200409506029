import { Button } from "primereact/button";
import type { KpiInputValues } from "../../../../../api-requests/settings";
import { syncOpenModalDialog } from "../../../../../global-tools/dialog-system";
import type { SchemaField } from "../../../../../helpers/format-databases";
import EditIcon from "../../../../icons/EditIcon";
import type { KpiInputValuesExtended } from "../DefineKpi";
import makeDefineKpiFormDialog, { type DefineKpiFormDialogParameter } from "../DefineKpiFormDialog";

import "./KpiCard.scss";

export default function KpiCard({
  kpi,
  kpiSchemaFields,
  editKpi,
}: {
  kpi: KpiInputValuesExtended;
  kpiSchemaFields: SchemaField[];
  editKpi(id: string, newVal: KpiInputValues): void;
}) {
  return (
    <div>
      <div className={`KpiCard${kpi.error ? " invalid" : ""}`}>
        <div className="KpiCard-topLine">
          <div>
            <span className="KpiCard-label">Libellé KPI</span>
            <span className="KpiCard-value">{kpi.name}</span>
          </div>

          <Button
            icon={<EditIcon />}
            className="p-outlined-primary IconOnlyBtn flex justify-content-end"
            rounded
            text
            onClick={(e) => {
              e.preventDefault();
              syncOpenModalDialog<DefineKpiFormDialogParameter>(makeDefineKpiFormDialog, {
                title: `Editer KPI '${kpi.name}'`,
                kpiToEdit: kpi,
                kpiSchemaFields,
                save: editKpi,
              });
            }}
          />
        </div>

        <div className="KpiCard-bottomLine">
          <div>
            <span className="KpiCard-label">Propriété KPI</span>
            <span className="KpiCard-value">{kpi.property}</span>
          </div>
          <div>
            <span className="KpiCard-label alignRight">Valeur KPI</span>
            <span className="KpiCard-value alignRight">{kpi.value}</span>
          </div>
        </div>
      </div>
      {kpi.error && <small className="Field-errorMessage">{kpi.error}</small>}
    </div>
  );
}
