import { nbVal, strVal } from "@paroi/data-formatters-lib";

const localStorageKey = "sess";
export let globalSession: UserSession | undefined;

export interface UserSession {
  id: number;
  token: string;
}

export async function verifyUserSession(): Promise<UserSession | undefined> {
  const raw = localStorage.getItem(localStorageKey);
  if (!raw) return;
  const stored = formatUserSession(JSON.parse(raw));
  if (!stored) return;
  globalSession = stored;
  return stored;
}

export function keepSession(newSession: UserSession) {
  globalSession = newSession;
  localStorage.setItem(localStorageKey, JSON.stringify(newSession));
}

export function forgetSession() {
  localStorage.removeItem(localStorageKey);
  globalSession = undefined;
}

function formatUserSession(payload: any): UserSession {
  return {
    id: nbVal(payload.id),
    token: strVal(payload.token),
  };
}
