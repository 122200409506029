import { strValOrUndef } from "@paroi/data-formatters-lib";
import { createAppLog } from "@paroicms/front-app-log";

const backendBaseUrl = strValOrUndef(import.meta.env.VITE_BACKEND_BASE_URL, {
  varName: "VITE_BACKEND_BASE_URL",
});
if (!backendBaseUrl) {
  throw new Error("VITE_API_BASE_URL env variable not defined");
}
export const BACKEND_BASE_URL = backendBaseUrl;
export const BACKEND_API_URL = `${backendBaseUrl}/api`;

export const LOGIN_URL =
  "https://pprodofficial.service-public.bj/official/login?client_id=app-referentiel-backend&redirect_uri=https://referentiel-admin.xroad.bj/auth/front-ldap&scope=openid&response_type=code&authError=true";

export const appLog = createAppLog({ minLevel: "debug" });
