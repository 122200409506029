import { InputSwitch } from "primereact/inputswitch";
import { useEffect } from "react";
import type { SchemaField } from "../../../helpers/format-databases";

export default function BooleanInput({
  label,
  value,
  field,
  onChange,
}: {
  label?: string;
  value: boolean;
  field: SchemaField;
  onChange: (value: boolean) => void;
}) {
  useEffect(() => {
    // A default value if set so we need to notify the parent
    onChange(value);
  }, []);

  return (
    <label className="Field">
      <span className="Field-label">
        {label ?? field.label}{" "}
        {field.required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
      </span>
      <InputSwitch checked={value} onChange={(e) => onChange(e.value)} />
    </label>
  );
}
