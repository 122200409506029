import type { Obj } from "@paroicms/anywhere-lib";
import { type Statistics, formatStatistics } from "../helpers/format-statistics";
import { fetchQuery } from "../helpers/http-client";

export interface RequestError {
  error: string;
}

export async function fetchStatisticsByReference(
  id: number,
  options?: {
    startedAt: string;
    endedAt: string;
  },
): Promise<Statistics> {
  const { data } = await fetchQuery<Obj>(
    `stats/${id}${options ? `?started_at=${options.startedAt}&ended_at=${options.endedAt}` : ""}`,
  );
  return formatStatistics(data);
}

export async function fetchDashboardStatistics(): Promise<Statistics> {
  const { data } = await fetchQuery<Obj>("stats/dashboard");
  return formatStatistics(data);
}
