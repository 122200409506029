import { Tooltip } from "primereact/tooltip";
import type { Nullable } from "primereact/ts-helpers";
import { useMemo } from "react";
import {
  isDateFieldType,
  isMultipleChoiceFieldType,
  isNumberFieldType,
  isTextFieldType,
} from "../../../../helpers/check-schema-field-type";
import type { SchemaFieldOption, SchemaFieldTypes } from "../../../../helpers/format-databases";
import AIcon from "../../../icons/AIcon";
import CalendarIcon from "../../../icons/CalendarIcon";
import DotCircleIcon from "../../../icons/DotCircleIcon";
import ListIcon from "../../../icons/ListIcon";
import NumberIcon from "../../../icons/NumberIcon";
import MultipleChoiceOptions from "./MultipleChoiceOptions";

import "./SelectSchemaFieldType.scss";

export interface SelectSchemaFieldTypeProps {
  schemaFieldTypes: SchemaFieldTypes;
  type: string;
  fieldLength: Nullable<number | null>;
  options?: SchemaFieldOption[];
  onChange: (data: {
    type: string;
    fieldLength: Nullable<number | null>;
    options?: SchemaFieldOption[];
  }) => void;
}

export function SelectSchemaFieldType({
  schemaFieldTypes,
  fieldLength,
  type,
  options,
  onChange,
}: SelectSchemaFieldTypeProps) {
  const makeFieldTypes = useMemo(() => {
    const fieldTypes: JSX.Element[] = [];
    for (const [key, val] of schemaFieldTypes.entries()) {
      const tooltipClassname = `Tooltip-${key}`;
      fieldTypes.push(
        <div key={key}>
          {val.length > 16 && <Tooltip target={`.${tooltipClassname}`} position="top" />}
          <button
            type="button"
            className={`${tooltipClassname} FieldTypeBtn ${type === key ? "active" : ""}`}
            onClick={() =>
              onChange({
                type: key,
                fieldLength,
                options,
              })
            }
            data-pr-tooltip={val}
          >
            {getIcon(key)}
            <div className="FieldTypeBtn-label">
              <span className="FieldTypeBtn-labelText">{val}</span>
            </div>
          </button>
        </div>,
      );
    }
    return fieldTypes;
  }, [schemaFieldTypes, type]);

  return (
    <div className="SelectSchemaFieldType">
      <label className="Field" style={{ marginTop: "27px", marginBottom: "10px" }}>
        <span className="Field-label">Type du champ</span>
        <div
          style={{ overflowX: "auto", paddingBottom: "10px" }}
          className="SelectSchemaFieldType-fieldTypeList"
        >
          {makeFieldTypes}
        </div>
      </label>

      {type === "enum" && (
        <MultipleChoiceOptions
          options={options}
          onChange={(newOptions) =>
            onChange({
              type,
              fieldLength,
              options: newOptions,
            })
          }
        />
      )}
    </div>
  );
}

function getIcon(type: string) {
  if (isTextFieldType(type)) {
    return <AIcon />;
  }
  if (isNumberFieldType(type)) {
    return <NumberIcon />;
  }
  if (isMultipleChoiceFieldType(type)) return <ListIcon />;
  if (isDateFieldType(type)) return <CalendarIcon />;
  if (type === "button") return <DotCircleIcon />;
  return <AIcon />;
}
