import { boolValOrUndef, nbValOrUndef, strValOrUndef } from "@paroi/data-formatters-lib";
import type { Ref } from "react";
import type { ReferenceDatumInputVal } from "../../../api-requests/reference-data";
import {
  checkIsDateFieldType,
  checkIsNumberFieldType,
  isMultipleChoiceFieldType,
  isTextFieldType,
} from "../../../helpers/check-schema-field-type";
import type { SchemaField } from "../../../helpers/format-databases";
import BooleanInput from "./BooleanInput";
import DateInput from "./DateInput";
import { FileInput, type FileInputHandle } from "./FileInput/FileInput";
import MultipleChoiceInput from "./MultipleChoiceInput";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";

export interface SchemaFieldInputProps {
  label?: string;
  value?: ReferenceDatumInputVal;
  field: SchemaField;
  forwardedRef: Ref<FileInputHandle>;
  onError: (value: boolean) => void;
  onChange: ({
    name,
    value,
  }: {
    name: string;
    value?: ReferenceDatumInputVal;
  }) => void;
}

export default function SchemaFieldInput({
  field,
  value,
  label,
  forwardedRef,
  onChange,
  onError,
}: SchemaFieldInputProps) {
  if (isTextFieldType(field.type)) {
    return (
      <TextInput
        label={label}
        value={strValOrUndef(value) ?? ""}
        field={field}
        onError={onError}
        onChange={(value) => onChange({ name: field.name, value })}
      />
    );
  }
  if (checkIsNumberFieldType(field.type)) {
    return (
      <NumberInput
        label={label}
        value={nbValOrUndef(value) ?? null}
        field={field}
        type={field.type}
        onError={onError}
        onChange={(value) => onChange({ name: field.name, value })}
      />
    );
  }
  if (checkIsDateFieldType(field.type)) {
    const dateString = strValOrUndef(value);
    return (
      <DateInput
        label={label}
        value={dateString ? new Date(dateString) : null}
        field={field}
        onError={onError}
        onChange={(value) => onChange({ name: field.name, value })}
        type={field.type}
      />
    );
  }
  if (field.type === "boolean") {
    return (
      <BooleanInput
        label={label}
        value={boolValOrUndef(value) ?? false}
        field={field}
        onChange={(value) => onChange({ name: field.name, value })}
      />
    );
  }
  if (isMultipleChoiceFieldType(field.type)) {
    if (field.options === undefined) {
      throw new Error("No options for multiple choice field");
    }
    return (
      <MultipleChoiceInput
        value={strValOrUndef(value)}
        field={field}
        label={label ?? field.label}
        options={field.options}
        onChange={(value) => onChange({ name: field.name, value })}
      />
    );
  }
  if (field.type === "file") {
    const val = value instanceof File ? value : undefined;
    return (
      <FileInput
        forwardedRef={forwardedRef}
        label={label}
        currentFile={val}
        field={field}
        onChange={(value) => onChange({ name: field.name, value })}
        onError={onError}
      />
    );
  }
  throw new Error("Unhandled schema field type");
}
