import { listVal, nbVal, strVal } from "@paroi/data-formatters-lib";
import { type Organization, formatOrganization } from "./format-databases";
import { type Role, formatRole } from "./format-roles";

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  fullname: string;
  email: string;
  organization?: Organization;
  roles: Role[];
}

export function formatUser(data: any): User {
  return {
    id: nbVal(data.id),
    firstname: strVal(data.firstname),
    lastname: strVal(data.lastname),
    fullname: strVal(data.full_name),
    email: strVal(data.email),
    organization: data.organization ? formatOrganization(data.organization) : undefined,
    roles: listVal(data.roles, formatRole),
  };
}
