import dayjs from "dayjs";
import "dayjs/locale/fr";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(isSameOrAfter);
dayjs.extend(LocalizedFormat);

export function dayJsFormat(date: string | Date, format: string | undefined = "lll") {
  return dayjs(date).format(format);
}

export function createDayJs(date?: string | number | dayjs.Dayjs | Date | null) {
  return dayjs(date);
}
