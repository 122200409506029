import { listVal, nbVal, nbValOrUndef, strVal, strValOrUndef } from "@paroi/data-formatters-lib";
import type { Obj } from "@paroicms/anywhere-lib";
import { type Database, formatDatabase } from "./format-databases";

export interface Settings {
  id: number;
  defaultReferenceId?: number;
  defaultReference?: Database;
  references?: References;
}

export type References = Map<
  string,
  {
    kpis: Kpi[];
    histogramKpi?: HistogramKpi;
  }
>;

export interface Kpi {
  name?: string;
  property: string;
  value?: string;
}

export interface HistogramKpi {
  name: string;
  property: string;
}

export function formatSettings(data: any): Settings {
  return {
    id: nbVal(data.id),
    defaultReferenceId: nbValOrUndef(data.default_reference_id),
    defaultReference: data.default_reference ? formatDatabase(data.default_reference) : undefined,
    references: data.references ? formateReferences(data.references) : undefined,
  };
}

function formateReferences(data: Obj): References {
  const result: References = new Map();
  for (const [key, val] of Object.entries(data)) {
    const refData = val as any;
    let histogramKpi: HistogramKpi | undefined;
    if (refData?.histogramKpi) {
      histogramKpi = {
        name: strVal(refData.histogramKpi.name),
        property: strVal(refData.histogramKpi.property),
      };
    }
    result.set(key, {
      kpis: listVal(refData.kpis, formatKpi),
      histogramKpi,
    });
  }
  return result;
}

function formatKpi(data: any): Kpi {
  return {
    name: strValOrUndef(data.name),
    property: strVal(data.porperty),
    value: strValOrUndef(data.value),
  };
}
