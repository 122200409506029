export default function StatisticsIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0625 1.48047C12.0625 1.09375 12.3203 0.75 12.75 0.75C18.0352 0.75 22.375 5.08984 22.375 10.375C22.375 10.7617 22.0312 11.0625 21.6445 11.0625H12.0625V1.48047ZM0.375 12.4375C0.375 7.23828 4.24219 2.94141 9.22656 2.25391C9.65625 2.16797 10 2.51172 10 2.89844V13.125L16.7031 19.8711C17.0039 20.1719 16.9609 20.6445 16.6602 20.8594C14.9414 22.0625 12.8789 22.75 10.6875 22.75C4.97266 22.75 0.375 18.1523 0.375 12.4375ZM22.9766 13.125C23.3633 13.125 23.707 13.4688 23.6211 13.8555C23.3203 16.2617 22.1602 18.4102 20.4414 20C20.1836 20.2148 19.7969 20.2148 19.5391 19.957L12.75 13.125H22.9766Z"
        fill="currentColor"
      />
    </svg>
  );
}
