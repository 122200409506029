import { nbVal, nbValOrUndef } from "@paroi/data-formatters-lib";
import { useWrapAsync } from "@paroicms/front-app-log";
import { Suspense, lazy, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchDatabases } from "../../../api-requests/databases";
import { getReferences } from "../../../helpers/get-references";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import Navbar from "../../parts/Navbar/Navbar";
import NoDataMessage from "../../utils/NoDataMessage/NoDataMessage";
import OurDropdown from "../../utils/OurDropdown/OurDropdown";
import Spinner from "../../utils/Spinner/Spinner";

const LoadDataTable = lazy(() => import("./LoadDataTable"));

export default function ReferenceDataListScreen() {
  const { referenceId } = useParams();
  const formatedReferenceId = nbValOrUndef(referenceId);
  const wrapAsync = useWrapAsync();
  const references = useAppStore((state) => state.references);
  const setReferences = useAppStore((state) => state.setReferences);
  const [selectedReferenceId, setSelectedReferenceId] = useState<number>();

  useEffect(
    wrapAsync(async () => {
      if (references === undefined) {
        await apiRequestWrapper(async () => {
          const result = await fetchDatabases();
          setReferences(getReferences(result.data));
        });
      }
    }),
    [references],
  );
  useEffect(() => {
    if (formatedReferenceId !== undefined) setSelectedReferenceId(formatedReferenceId);
  }, [formatedReferenceId]);

  if (!references) {
    return <Spinner />;
  }
  return (
    <div>
      <Navbar pageTitle="Enregistrement" />

      <div className="Container">
        <div className="Container-content">
          <div
            className="Card"
            style={{
              display: "flex",
              alignItems: "end",
              gap: "10px",
              textAlign: "left",
              padding: "15px 21px 39px 26px",
            }}
          >
            <label className="Field w-full">
              <span className="Field-label">Sélectionner un référentiel</span>
              <OurDropdown<number>
                selectedOption={selectedReferenceId}
                options={references.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
                onChange={(e) => setSelectedReferenceId(nbVal(e))}
                filter
                leftIcon
              />
            </label>
          </div>

          {selectedReferenceId === undefined ? (
            <NoDataMessage message="Aucun référentiel sélectionné" />
          ) : (
            <Suspense fallback={<Spinner />}>
              <LoadDataTable selectedReferenceId={selectedReferenceId} />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
}
