export default function ReferencesIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 15.1875C19.25 15.832 18.9492 16.3477 18.5625 16.7773V20.2578C18.9492 20.5156 19.25 20.9453 19.25 21.418C19.25 22.1484 18.6055 22.75 17.875 22.75H4.125C1.80469 22.75 0 20.9453 0 18.625V4.875C0 2.59766 1.80469 0.75 4.125 0.75H17.1875C18.3047 0.75 19.25 1.69531 19.25 2.8125V15.1875ZM6.14453 6.25C5.80078 6.25 5.5 6.59375 5.5 6.9375C5.5 7.32422 5.80078 7.625 6.14453 7.625H14.4375C14.7812 7.625 15.125 7.32422 15.125 6.9375C15.125 6.59375 14.7812 6.25 14.3945 6.25H6.14453ZM6.14453 9C5.80078 9 5.5 9.34375 5.5 9.6875C5.5 10.0742 5.80078 10.375 6.14453 10.375H14.4375C14.7812 10.375 15.125 10.0742 15.125 9.6875C15.125 9.34375 14.7812 9 14.3945 9H6.14453ZM16.5 20V17.25H4.125C3.35156 17.25 2.75 17.8945 2.75 18.625C2.75 19.3984 3.35156 20 4.125 20H16.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
