import mitt from "mitt";

const mittBus = mitt();

export type EventBusEName = "404" | "fatalError" | "globalRepaint";

export interface EventBus {
  emit(eventName: "404"): void;
  emit(eventName: "fatalError", message: string): void;
  emit(eventName: "globalRepaint"): void;
  on(eventName: "404", callback: () => void): void;
  on(eventName: "fatalError", callback: (message: string) => void): void;
  on(eventName: "globalRepaint", callback: () => void): void;
  off(eventName: "404", callback: () => void): void;
  off(eventName: "fatalError", callback: (message: string) => void): void;
  off(eventName: "globalRepaint", callback: () => void): void;
}

export const eventBus: EventBus = {
  emit(eventName: EventBusEName, message?: string) {
    setTimeout(() => mittBus.emit(eventName, message), 0);
  },
  on(eventName: EventBusEName, callback: (message?: any) => void) {
    mittBus.on(eventName, callback);
  },
  off(eventName: EventBusEName, callback: (message?: any) => void) {
    mittBus.off(eventName, callback);
  },
};
