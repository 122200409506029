import { listVal } from "@paroi/data-formatters-lib";
import { type ReferenceDatum, formatReferenceDatum } from "../helpers/format-reference-data";
import { type Pagination, fetchQuery } from "../helpers/http-client";

export interface ApiRequestResponse<T> {
  data: T;
  pagination?: Pagination;
}

export async function fetchReferenceData(
  referenceId: number,
): Promise<ApiRequestResponse<ReferenceDatum[]>> {
  const res = await fetchQuery(`references/${referenceId}/data`);
  return {
    data: listVal(res.data, formatReferenceDatum),
    pagination: res.pagination,
  };
}

export type ReferenceDatumInput = Map<string, ReferenceDatumInputVal | undefined>;
export type ReferenceDatumInputVal = string | number | boolean | File;

export async function createReferenceDatum(
  referenceId: number,
  body: ReferenceDatumInput | FormData,
) {
  await fetchQuery(`references/${referenceId}/data`, {
    method: "POST",
    body: convertToJsonIfNotFormData(body),
  });
}

export async function updateReferenceDatum({
  referenceId,
  datumId,
  body,
}: {
  referenceId: number;
  datumId: number;
  body: ReferenceDatumInput | FormData;
}) {
  await fetchQuery(`references/${referenceId}/data/${datumId}`, {
    method: "PUT",
    body: convertToJsonIfNotFormData(body),
  });
}

export async function deleteReferenceDatum({
  referenceId,
  datumId,
}: {
  referenceId: number;
  datumId: number;
}): Promise<void> {
  await fetchQuery(`references/${referenceId}/data/${datumId}`, {
    method: "DELETE",
  });
}

function convertToJsonIfNotFormData(body: ReferenceDatumInput | FormData): string | FormData {
  if (body instanceof FormData) return body;
  return JSON.stringify(Object.fromEntries(body.entries()));
}
