export function hasUrlParameter(parameterName: string): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(parameterName);
}

export function getUrlParameter(parameterName: string): string | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(parameterName) ?? undefined;
}

export function replaceUrlParameters(parameters?: Record<string, string>) {
  const urlParams = parameters ? new URLSearchParams(parameters) : undefined;
  history.replaceState(null, "", urlParams ? `/?${urlParams.toString()}` : "/");
}
