export default function HomeIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.707 11.75C24.707 12.5234 24.0625 13.125 23.332 13.125H21.957L22 20C22 20.1289 22 20.2578 22 20.3438V21.0312C22 22.0195 21.2266 22.75 20.2812 22.75H19.5938C19.5078 22.75 19.4648 22.75 19.4219 22.75C19.3789 22.75 19.293 22.75 19.25 22.75H16.8438C15.8555 22.75 15.125 22.0195 15.125 21.0312V17.25C15.125 16.5195 14.4805 15.875 13.75 15.875H11C10.2266 15.875 9.625 16.5195 9.625 17.25V21.0312C9.625 22.0195 8.85156 22.75 7.90625 22.75H5.5C5.41406 22.75 5.37109 22.75 5.28516 22.75C5.24219 22.75 5.19922 22.75 5.15625 22.75H4.46875C3.48047 22.75 2.75 22.0195 2.75 21.0312V16.2188C2.75 16.2188 2.75 16.1758 2.75 16.1328V13.125H1.375C0.601562 13.125 0 12.5234 0 11.75C0 11.3633 0.128906 11.0195 0.429688 10.7188L11.4297 1.13672C11.7305 0.835938 12.0742 0.75 12.375 0.75C12.6758 0.75 13.0195 0.878906 13.2773 1.09375L24.2344 10.7188C24.5781 11.0195 24.75 11.3633 24.707 11.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
