import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import DatabaseCreateScreen from "../components/screens/DatabaseCreateScreen/DatabaseCreateScreen";
import DatabaseEditScreen from "../components/screens/DatabaseEditScreen/DatabaseEditScreen";
import DatabaseListScreen from "../components/screens/DatabaseListScreen/DatabaseListScreen";
import DatabaseViewScreen from "../components/screens/DatabaseViewScreen/DatabaseViewScreen";
import ErrorNotFoundScreen from "../components/screens/ErrorNotFoundScreen/ErrorNotFoundScreen";
import ErrorUnexpectedScreen from "../components/screens/ErrorUnexpectedScreen/ErrorUnexpectedScreen";
import HomeScreen from "../components/screens/HomeScreen/HomeScreen";
import ReferenceDataCreateScreen from "../components/screens/ReferenceDataCreateScreen/ReferenceDataCreateScreen";
import ReferenceDataListScreen from "../components/screens/ReferenceDataListScreen/ReferenceDataListScreen";
import ReferenceListScreen from "../components/screens/ReferenceListScreen/ReferenceListScreen";
import SettingsScreen from "../components/screens/SettingsScreen/SettingsScreen";
import StatisticsScreen from "../components/screens/StatisticsScreen/StatisticsScreen";
import Spinner from "../components/utils/Spinner/Spinner";
import AuthenticatedApp from "./AuthenticatedApp";

// Lazy load rarely used screens
const SupportScreen = lazy(() => import("../components/screens/SupportScreen/SupportScreen"));
const JournalsScreen = lazy(() => import("../components/screens/JournalsScreen/JournalsScreen"));
const UserManagementScreen = lazy(
  () => import("../components/screens/UserManagementScreen/UserManagementScreen"),
);
const DeveloperScreen = lazy(() => import("../components/screens/DeveloperScreen/DeveloperScreen"));

export default function buildAppRouter() {
  return createBrowserRouter([
    {
      path: "/",
      element: <AuthenticatedApp />,
      errorElement: <ErrorUnexpectedScreen message="Erreur lors du chargement de l'application" />,

      children: [
        {
          path: "",
          element: <HomeScreen />,
        },
        {
          path: "databases",
          children: [
            {
              path: "",
              element: <DatabaseListScreen />,
            },
            {
              path: "create",
              element: <DatabaseCreateScreen />,
            },
            {
              path: ":databaseId/view",
              element: <DatabaseViewScreen />,
            },
            {
              path: ":databaseId/edit",
              element: <DatabaseEditScreen />,
            },
          ],
        },
        {
          path: "references",
          children: [
            {
              path: "",
              element: <ReferenceListScreen />,
            },
            {
              path: "create-data",
              element: <ReferenceDataCreateScreen />,
            },
            {
              path: ":referenceId/create-data",
              element: <ReferenceDataCreateScreen />,
            },
            {
              path: "data",
              element: <ReferenceDataListScreen />,
            },
            {
              path: ":referenceId/data",
              element: <ReferenceDataListScreen />,
            },
          ],
        },
        {
          path: "journals",
          element: (
            <Suspense fallback={<Spinner />}>
              <JournalsScreen />
            </Suspense>
          ),
        },
        {
          path: "statistics",
          element: <StatisticsScreen />,
        },
        {
          path: "settings",
          children: [
            {
              path: "",
              element: <SettingsScreen />,
            },
            {
              path: "users",
              element: (
                <Suspense fallback={<Spinner />}>
                  <UserManagementScreen />
                </Suspense>
              ),
            },
            {
              path: "developer",
              element: (
                <Suspense fallback={<Spinner />}>
                  <DeveloperScreen />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "support",
          element: (
            <Suspense fallback={<Spinner />}>
              <SupportScreen />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: <ErrorNotFoundScreen />,
        },
      ],
    },
  ]);
}
