export default function NumberIcon({ classNames }: { classNames?: string }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width="33"
      height="17"
      viewBox="0 0 33 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15C10 15.6328 9.47266 16.125 8.875 16.125H2.125C1.49219 16.125 1 15.6328 1 15C1 14.4023 1.49219 13.875 2.125 13.875H4.375V3.60938L2.72266 4.69922C2.23047 5.05078 1.52734 4.91016 1.17578 4.38281C0.824219 3.89062 0.964844 3.1875 1.49219 2.83594L4.86719 0.585938C5.21875 0.339844 5.64062 0.339844 6.02734 0.515625C6.37891 0.726562 6.625 1.11328 6.625 1.53516V13.9102H8.875C9.47266 13.875 10 14.4023 10 15ZM21.25 15C21.25 15.6328 20.7227 16.125 20.125 16.125H11.125C10.6328 16.125 10.2461 15.8789 10.0703 15.4219C9.89453 15 10 14.5078 10.3164 14.1914L17.1367 7.6875C18.2266 6.63281 18.332 4.875 17.3477 3.71484C16.8203 3.08203 16.082 2.73047 15.2734 2.66016C14.4648 2.625 13.6914 2.90625 13.0938 3.46875L11.8633 4.59375C11.4062 5.01562 10.7031 4.98047 10.2812 4.52344C9.85938 4.06641 9.89453 3.36328 10.3516 2.94141L11.5469 1.81641C12.5664 0.867188 13.9727 0.339844 15.3789 0.445312C16.8203 0.515625 18.1562 1.18359 19.0703 2.27344C20.8281 4.34766 20.6523 7.44141 18.6836 9.30469L13.9023 13.875H20.125C20.7227 13.875 21.25 14.4023 21.25 15ZM32.5 11.3438C32.5 13.9805 30.3203 16.125 27.7188 16.125H24.8711C23.2539 16.125 21.8125 15.1055 21.3203 13.5586C21.1094 12.9609 21.4258 12.3281 22.0234 12.1523C22.6211 11.9414 23.2539 12.2578 23.4648 12.8555C23.6758 13.4883 24.2383 13.875 24.9062 13.875H27.7188C29.0898 13.875 30.25 12.75 30.25 11.3438C30.25 9.97266 29.0898 8.8125 27.7188 8.8125H24.0625C23.5703 8.8125 23.1484 8.53125 23.0078 8.10938C22.832 7.6875 22.9375 7.19531 23.2891 6.87891L27.8945 2.625H22.375C21.7422 2.625 21.25 2.13281 21.25 1.5C21.25 0.902344 21.7422 0.375 22.375 0.375H30.8125C31.2695 0.375 31.6914 0.691406 31.832 1.11328C32.0078 1.53516 31.9023 2.02734 31.5508 2.34375L26.9453 6.5625H27.7188C30.3203 6.5625 32.5 8.70703 32.5 11.3438Z"
        fill="black"
      />
    </svg>
  );
}
