import { nbValOrUndef } from "@paroi/data-formatters-lib";
import { InputNumber } from "primereact/inputnumber";
import { useState } from "react";
import type { SchemaFieldNumberType } from "../../../helpers/check-schema-field-type";
import type { SchemaField } from "../../../helpers/format-databases";

export default function NumberInput({
  label,
  value,
  field,
  type,
  onChange,
  onError,
}: {
  label?: string;
  value: number | null;
  field: SchemaField;
  type: SchemaFieldNumberType;
  onChange: (value?: number) => void;
  onError: (value: boolean) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string>();

  const onValueChange = (val: number | null) => {
    if (field.required && nbValOrUndef(val) === undefined) {
      setErrorMessage("Ce champ est requis.");
      onError(true);
    } else {
      setErrorMessage(undefined);
      onError(false);
    }
  };

  return (
    <label className="Field">
      <span className="Field-label">
        {label ?? field.label}{" "}
        {field.required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
      </span>
      <InputNumber
        className="Field-inputNumber w-full"
        maxFractionDigits={type === "decimal" || type === "double" ? 20 : undefined}
        value={value}
        onChange={(ev) => {
          const val = ev.value;
          onChange(val === null ? undefined : val);
          onValueChange(val);
        }}
        invalid={errorMessage !== undefined}
        required={field.required}
      />
      {errorMessage && <small className="Field-errorMessage">{errorMessage}</small>}
    </label>
  );
}
