import { nbVal, strVal, strValOrUndef } from "@paroi/data-formatters-lib";

export interface ReferenceDatum {
  id: number;
  readonly [field: string]: string | number | undefined;
  author?: string;
  createdAt: string;
  updatedAt: string;
  deleteAt?: string;
}

export function formatReferenceDatum(data: any): ReferenceDatum {
  return {
    id: nbVal(data.id),
    ...data,
    author: strValOrUndef(data.author),
    createdAt: strVal(data.created_at),
    updatedAt: strVal(data.updated_at),
    deleteAt: strValOrUndef(data.delete_at),
  };
}
