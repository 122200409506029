export default function AIcon({ classNames }: { classNames?: string }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.91 13L6.58 0.399999H8.884L14.572 13H12.124L7.246 1.642H8.182L3.322 13H0.91ZM3.52 10.084L4.15 8.248H10.954L11.584 10.084H3.52Z"
        fill="white"
      />
    </svg>
  );
}
