export default function SettingsIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2695 7.92578C21.4414 8.3125 21.3125 8.69922 21.0117 9L19.1641 10.6758C19.207 11.0195 19.25 11.4062 19.25 11.75C19.25 12.1367 19.207 12.5234 19.1641 12.8672L21.0117 14.543C21.3125 14.8438 21.4414 15.2305 21.2695 15.6172C21.0977 16.1328 20.8828 16.6055 20.625 17.0781L20.4102 17.4219C20.1094 17.8945 19.8086 18.3672 19.4648 18.7969C19.207 19.0977 18.7773 19.1836 18.3906 19.0547L16.0273 18.3242C15.4258 18.7539 14.7812 19.0977 14.1367 19.3984L13.5781 21.8477C13.4922 22.2344 13.1914 22.5352 12.8047 22.6211C12.2031 22.707 11.6016 22.75 10.957 22.75C10.3555 22.75 9.75391 22.707 9.15234 22.6211C8.76562 22.5352 8.46484 22.2344 8.37891 21.8477L7.82031 19.3984C7.17578 19.0977 6.53125 18.7539 5.92969 18.3242L3.56641 19.0547C3.17969 19.1836 2.75 19.0977 2.49219 18.7969C2.14844 18.3672 1.84766 17.8945 1.54688 17.4219L1.33203 17.0781C1.07422 16.6055 0.859375 16.1328 0.6875 15.6172C0.515625 15.2305 0.644531 14.8438 0.945312 14.543L2.79297 12.8672C2.75 12.5234 2.75 12.1367 2.75 11.75C2.75 11.4062 2.75 11.0195 2.79297 10.6758L0.945312 9C0.644531 8.69922 0.515625 8.3125 0.6875 7.92578C0.859375 7.41016 1.07422 6.9375 1.33203 6.46484L1.54688 6.12109C1.84766 5.64844 2.14844 5.17578 2.49219 4.74609C2.75 4.44531 3.17969 4.35938 3.56641 4.48828L5.92969 5.21875C6.53125 4.78906 7.17578 4.40234 7.82031 4.14453L8.37891 1.69531C8.46484 1.30859 8.76562 1.00781 9.15234 0.921875C9.75391 0.835938 10.3555 0.75 11 0.75C11.6016 0.75 12.2031 0.835938 12.8047 0.921875C13.1914 1.00781 13.4922 1.30859 13.5781 1.69531L14.1367 4.14453C14.7812 4.40234 15.4258 4.78906 16.0273 5.21875L18.3906 4.48828C18.7773 4.35938 19.207 4.44531 19.4648 4.74609C19.8086 5.17578 20.1094 5.64844 20.4102 6.12109L20.625 6.46484C20.8828 6.9375 21.0977 7.41016 21.2695 7.92578ZM11 15.1875C12.8906 15.1875 14.4375 13.6836 14.4375 11.75C14.4375 9.85938 12.8906 8.3125 11 8.3125C9.06641 8.3125 7.5625 9.85938 7.5625 11.75C7.5625 13.6836 9.06641 15.1875 11 15.1875Z"
        fill="currentColor"
      />
    </svg>
  );
}
