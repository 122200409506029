import { useWrapAsync } from "@paroicms/front-app-log";
import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { fetchAuthUser } from "../api-requests/users";
import Spinner from "../components/utils/Spinner/Spinner";
import { appLog } from "../context";
import { eventBus } from "../global-tools/event-bus";
import { useAppStore } from "../store/app.store";
import buildAppRouter from "./router";

export default function AuthenticatedAppLoader() {
  const wrapAsync = useWrapAsync();
  const [ready, setReady] = useState(false);
  const setAuthUser = useAppStore((state) => state.setAuthUser);

  useEffect(
    wrapAsync(async () => {
      try {
        setReady(false);
        const authUser = await fetchAuthUser();
        setAuthUser(authUser);
        setReady(true);
      } catch (error) {
        appLog.error(error);
        eventBus.emit("fatalError", "Unexpected error when trying to fetch user data");
      }
    }),
    [],
  );

  if (!ready) return <Spinner />;

  return <RouterProvider fallbackElement={<Spinner />} router={buildAppRouter()} />;
}
