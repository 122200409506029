export default function DatabaseIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 4.1875V6.25C19.25 8.18359 14.9102 9.6875 9.625 9.6875C4.29688 9.6875 0 8.18359 0 6.25V4.1875C0 2.29688 4.29688 0.75 9.625 0.75C14.9102 0.75 19.25 2.29688 19.25 4.1875ZM16.8867 9.98828C17.7461 9.6875 18.6055 9.25781 19.25 8.78516V13.125C19.25 15.0586 14.9102 16.5625 9.625 16.5625C4.29688 16.5625 0 15.0586 0 13.125V8.78516C0.601562 9.25781 1.46094 9.6875 2.32031 9.98828C4.25391 10.6758 6.83203 11.0625 9.625 11.0625C12.375 11.0625 14.9531 10.6758 16.8867 9.98828ZM2.32031 16.8633C4.25391 17.5508 6.83203 17.9375 9.625 17.9375C12.375 17.9375 14.9531 17.5508 16.8867 16.8633C17.7461 16.5625 18.6055 16.1328 19.25 15.6602V19.3125C19.25 21.2461 14.9102 22.75 9.625 22.75C4.29688 22.75 0 21.2461 0 19.3125V15.6602C0.601562 16.1328 1.46094 16.5625 2.32031 16.8633Z"
        fill="currentColor"
      />
    </svg>
  );
}
