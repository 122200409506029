export default function ListIcon({ classNames }: { classNames?: string }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.09375 0.9375C3.55078 0.9375 3.9375 1.32422 3.9375 1.78125V3.46875C3.9375 3.96094 3.55078 4.3125 3.09375 4.3125H1.40625C0.914062 4.3125 0.5625 3.96094 0.5625 3.46875V1.78125C0.5625 1.32422 0.914062 0.9375 1.40625 0.9375H3.09375ZM16.875 1.5C17.4727 1.5 18 2.02734 18 2.625C18 3.25781 17.4727 3.75 16.875 3.75H6.75C6.11719 3.75 5.625 3.25781 5.625 2.625C5.625 2.02734 6.11719 1.5 6.75 1.5H16.875ZM16.875 7.125C17.4727 7.125 18 7.65234 18 8.25C18 8.88281 17.4727 9.375 16.875 9.375H6.75C6.11719 9.375 5.625 8.88281 5.625 8.25C5.625 7.65234 6.11719 7.125 6.75 7.125H16.875ZM16.875 12.75C17.4727 12.75 18 13.2773 18 13.875C18 14.5078 17.4727 15 16.875 15H6.75C6.11719 15 5.625 14.5078 5.625 13.875C5.625 13.2773 6.11719 12.75 6.75 12.75H16.875ZM0.5625 7.40625C0.5625 6.94922 0.914062 6.5625 1.40625 6.5625H3.09375C3.55078 6.5625 3.9375 6.94922 3.9375 7.40625V9.09375C3.9375 9.58594 3.55078 9.9375 3.09375 9.9375H1.40625C0.914062 9.9375 0.5625 9.58594 0.5625 9.09375V7.40625ZM3.09375 12.1875C3.55078 12.1875 3.9375 12.5742 3.9375 13.0312V14.7188C3.9375 15.2109 3.55078 15.5625 3.09375 15.5625H1.40625C0.914062 15.5625 0.5625 15.2109 0.5625 14.7188V13.0312C0.5625 12.5742 0.914062 12.1875 1.40625 12.1875H3.09375Z"
        fill="black"
      />
    </svg>
  );
}
