export default function SlidersHIcon({ classNames }: { classNames?: string }) {
  return (
    <svg
      className={`SvgIcon${classNames ? ` ${classNames}` : ""}`}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14.875C0 14.2773 0.492188 13.75 1.125 13.75H3.02344C3.48047 12.7656 4.46484 12.0625 5.625 12.0625C6.75 12.0625 7.73438 12.7656 8.19141 13.75H16.875C17.4727 13.75 18 14.2773 18 14.875C18 15.5078 17.4727 16 16.875 16H8.19141C7.73438 17.0195 6.75 17.6875 5.625 17.6875C4.46484 17.6875 3.48047 17.0195 3.02344 16H1.125C0.492188 16 0 15.5078 0 14.875ZM6.75 14.875C6.75 14.2773 6.22266 13.75 5.625 13.75C4.99219 13.75 4.5 14.2773 4.5 14.875C4.5 15.5078 4.99219 16 5.625 16C6.22266 16 6.75 15.5078 6.75 14.875ZM12.375 6.4375C13.5 6.4375 14.4844 7.14062 14.9414 8.125H16.875C17.4727 8.125 18 8.65234 18 9.25C18 9.88281 17.4727 10.375 16.875 10.375H14.9414C14.4844 11.3945 13.5 12.0625 12.375 12.0625C11.2148 12.0625 10.2305 11.3945 9.77344 10.375H1.125C0.492188 10.375 0 9.88281 0 9.25C0 8.65234 0.492188 8.125 1.125 8.125H9.77344C10.2305 7.14062 11.2148 6.4375 12.375 6.4375ZM13.5 9.25C13.5 8.65234 12.9727 8.125 12.375 8.125C11.7422 8.125 11.25 8.65234 11.25 9.25C11.25 9.88281 11.7422 10.375 12.375 10.375C12.9727 10.375 13.5 9.88281 13.5 9.25ZM16.875 2.5C17.4727 2.5 18 3.02734 18 3.625C18 4.25781 17.4727 4.75 16.875 4.75H9.31641C8.85938 5.76953 7.875 6.4375 6.75 6.4375C5.58984 6.4375 4.60547 5.76953 4.14844 4.75H1.125C0.492188 4.75 0 4.25781 0 3.625C0 3.02734 0.492188 2.5 1.125 2.5H4.14844C4.60547 1.51562 5.58984 0.8125 6.75 0.8125C7.875 0.8125 8.85938 1.51562 9.31641 2.5H16.875ZM5.625 3.625C5.625 4.25781 6.11719 4.75 6.75 4.75C7.34766 4.75 7.875 4.25781 7.875 3.625C7.875 3.02734 7.34766 2.5 6.75 2.5C6.11719 2.5 5.625 3.02734 5.625 3.625Z"
        fill="#BED9DA"
      />
    </svg>
  );
}
