export default function SupportIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1953 19.0547C20.7539 19.5703 20.7539 20.4727 20.1953 20.9883C19.6797 21.5469 18.7773 21.5469 18.2617 20.9883L17.7031 20.4727C15.8555 21.8906 13.5352 22.75 10.957 22.75C8.42188 22.75 6.10156 21.8906 4.25391 20.4727L3.69531 20.9883C3.17969 21.5469 2.27734 21.5469 1.76172 20.9883C1.20312 20.4727 1.20312 19.5703 1.76172 19.0547L2.27734 18.4961C0.859375 16.6484 0 14.3281 0 11.75C0 9.21484 0.859375 6.89453 2.27734 5.04688L1.76172 4.48828C1.20312 3.97266 1.20312 3.07031 1.76172 2.55469C2.27734 1.99609 3.17969 1.99609 3.69531 2.55469L4.25391 3.07031C6.10156 1.65234 8.42188 0.75 10.957 0.75C13.5352 0.75 15.8555 1.65234 17.7031 3.07031L18.2617 2.55469C18.7773 1.99609 19.6797 1.99609 20.1953 2.55469C20.7539 3.07031 20.7539 3.97266 20.1953 4.48828L19.6797 5.04688C21.0977 6.89453 22 9.21484 22 11.75C22 14.3281 21.0977 16.6484 19.6797 18.4961L20.1953 19.0547ZM13.2773 16.0039C12.5898 16.3906 11.8164 16.5625 10.957 16.5625C10.1406 16.5625 9.36719 16.3906 8.67969 16.0039L6.1875 18.4961C7.5625 19.4414 9.19531 20 10.957 20C12.7617 20 14.3945 19.4414 15.7695 18.4961L13.2773 16.0039ZM19.25 11.75C19.25 9.98828 18.6484 8.35547 17.7031 6.98047L15.2109 9.47266C15.5977 10.1602 15.8125 10.9336 15.8125 11.75C15.8125 12.6094 15.5977 13.3828 15.2109 14.0703L17.7031 16.5625C18.6484 15.1875 19.25 13.5547 19.25 11.75ZM10.957 3.5C9.19531 3.5 7.5625 4.10156 6.1875 5.04688L8.67969 7.53906C9.36719 7.15234 10.1406 6.9375 10.957 6.9375C11.8164 6.9375 12.5898 7.15234 13.2773 7.53906L15.7695 5.04688C14.3945 4.10156 12.7617 3.5 11 3.5H10.957ZM6.74609 14.0703C6.35938 13.3828 6.14453 12.6094 6.14453 11.75C6.14453 10.9336 6.35938 10.1602 6.74609 9.47266L4.25391 6.98047C3.30859 8.35547 2.70703 9.98828 2.70703 11.75C2.70703 13.5547 3.30859 15.1875 4.25391 16.5625L6.74609 14.0703ZM10.957 9.6875C9.83984 9.6875 8.89453 10.6328 8.89453 11.75C8.89453 12.9102 9.83984 13.8125 10.957 13.8125C12.1172 13.8125 13.0625 12.9102 13.0625 11.75C13.0625 10.6328 12.1172 9.6875 10.957 9.6875Z"
        fill="currentColor"
      />
    </svg>
  );
}
