import { Calendar } from "primereact/calendar";
import type { Nullable } from "primereact/ts-helpers";
import { useState } from "react";
import type { SchemaFieldDateType } from "../../../helpers/check-schema-field-type";
import type { SchemaField } from "../../../helpers/format-databases";

export default function DateInput({
  label,
  value,
  type,
  field,
  onChange,
  onError,
}: {
  label?: string;
  value: Nullable<Date>;
  type: SchemaFieldDateType;
  field: SchemaField;
  onChange: (value?: string) => void;
  onError: (value: boolean) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string>();

  const onValueChange = (date: Nullable<Date>) => {
    if (field.required && !date) {
      setErrorMessage("Ce champ est requis.");
      onError(true);
    } else {
      setErrorMessage(undefined);
      onError(false);
    }
  };

  // TODO: improve better input error handling
  // useEffect(() => {
  //   onValueChange(value);
  // }, []);

  return (
    <label className="Field">
      <span className="Field-label">
        {label ?? field.label}{" "}
        {field.required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
      </span>
      <Calendar
        className="w-full"
        value={value}
        onChange={(e) => {
          onChange(e.value?.toISOString());
          onValueChange(e.value);
        }}
        timeOnly={type === "time"}
        showTime={type === "dateTime"}
        invalid={errorMessage !== undefined}
        required={field.required}
      />
      {errorMessage && <small className="Field-errorMessage">{errorMessage}</small>}
    </label>
  );
}
