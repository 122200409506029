import type { RequestError } from "../api-requests/statistics";

export type SchemaFieldDateType = "date" | "dateTime" | "time";

export function isDateFieldType(type: string) {
  if (type === "date" || type === "time" || type === "dateTime") {
    return true;
  }
  return false;
}

export function checkIsDateFieldType(type: string): type is SchemaFieldDateType {
  return isDateFieldType(type);
}

export type SchemaFieldNumberType = "decimal" | "double" | "bigInteger" | "integer";

export function isNumberFieldType(type: string) {
  if (type === "decimal" || type === "double" || type === "bigInteger" || type === "integer") {
    return true;
  }
  return false;
}

export function checkIsNumberFieldType(type: string): type is SchemaFieldNumberType {
  return isNumberFieldType(type);
}

export type SchemaFieldTextType = "text" | "string" | "longText" | "char";

export function isTextFieldType(type: string) {
  if (type === "text" || type === "string" || type === "longText" || type === "char") {
    return true;
  }
  return false;
}

export function checkIsTextFieldType(type: string): type is SchemaFieldTextType {
  return isTextFieldType(type);
}

export function isMultipleChoiceFieldType(type: string) {
  if (type === "enum") return true;
  return false;
}

export function checkIsRequestError<T extends object>(
  response: T | RequestError,
): response is RequestError {
  if ("error" in response) return true;
  return false;
}
