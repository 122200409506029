import { useAppLog, useWrapAsync } from "@paroicms/front-app-log";
import { useEffect, useState } from "react";
import { initialFetchAuthUser } from "../api-requests/users";
import Spinner from "../components/utils/Spinner/Spinner";
import { eventBus } from "../global-tools/event-bus";
import { forgetSession, keepSession, verifyUserSession } from "../global-tools/session-storage";
import { getUrlParameter, replaceUrlParameters } from "../global-tools/url-parameters";
import { useAppStore } from "../store/app.store";
import LoginScreen from "./LoginScreen/LoginScreen";

export default function UnauthenticatedApp() {
  const wrapAsync = useWrapAsync();
  const appLog = useAppLog();
  const [sessionLoading, setSessionLoading] = useState(true);
  const setAuthenticated = useAppStore((state) => state.setIsAuthenticated);

  useEffect(
    wrapAsync(async () => {
      try {
        setSessionLoading(true);
        const token = getUrlParameter("token");
        if (token) {
          forgetSession();
          const authUser = await initialFetchAuthUser(token);
          keepSession({ id: authUser.id, token });
          replaceUrlParameters();
          setAuthenticated(true);
          eventBus.emit("globalRepaint");
          return;
        }

        const session = await verifyUserSession();
        if (session) {
          setAuthenticated(true);
        }
      } catch (error) {
        appLog.error(error);
        eventBus.emit("fatalError", "Unexpected error when tying to authenticate user");
      } finally {
        setSessionLoading(false);
      }
    }),
    [],
  );

  if (sessionLoading) return <Spinner />;
  return <LoginScreen />;
}
