import { useWrapAsync } from "@paroicms/front-app-log";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { type ChangeEvent, useEffect, useState } from "react";
import { fetchDatabases } from "../../../api-requests/databases";
import { dayJsFormat } from "../../../global-tools/dayjs.helpers";
import { getReferences } from "../../../helpers/get-references";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import Navbar from "../../parts/Navbar/Navbar";
import NoDataMessage from "../../utils/NoDataMessage/NoDataMessage";
import Spinner from "../../utils/Spinner/Spinner";

import ReferenceCard from "./ReferenceCard/ReferenceCard";
import "./ReferenceListScreen.scss";

export default function ReferenceListScreen() {
  const wrapAsync = useWrapAsync();
  const [searchValue, setSearchValue] = useState("");
  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const references = useAppStore((state) => state.references);
  const setReferences = useAppStore((state) => state.setReferences);

  useEffect(
    wrapAsync(async () => {
      if (references === undefined) {
        await apiRequestWrapper(async () => {
          const result = await fetchDatabases();
          setReferences(getReferences(result.data));
        });
      }
    }),
    [references],
  );

  const referenceListView =
    references === undefined ? (
      <Spinner />
    ) : references.length > 0 ? (
      <div className="ReferenceListScreen-cardList">
        {references.map((reference) => (
          <ReferenceCard
            key={reference.id}
            route={`/references/${reference.id}/create-data`}
            label={reference.name}
            fields={reference.schemaNames.length}
            rows={reference.lineNumber}
            date={dayJsFormat(reference.createdAt, "DD/MM/YYYY")}
          />
        ))}
      </div>
    ) : (
      <NoDataMessage />
    );

  return (
    <div>
      <Navbar pageTitle="Liste des référentiels" />

      <div className="ReferenceListScreen Container">
        <div className="Container-content">
          <div className="ReferenceListScreen-header">
            <IconField iconPosition="left" className="InputWithIcon lg">
              <InputIcon className="pi pi-search" />
              <InputText value={searchValue} onChange={onSearchChange} placeholder="Recherche…" />
            </IconField>
          </div>

          {referenceListView}
        </div>
      </div>
    </div>
  );
}
