export default function DisconnectIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 16.625C7.60547 16.625 8.25 17.2695 8.25 18C8.25 18.7734 7.60547 19.375 6.875 19.375H4.125C1.80469 19.375 0 17.5703 0 15.25V4.25C0 1.97266 1.80469 0.125 4.125 0.125H6.875C7.60547 0.125 8.25 0.769531 8.25 1.5C8.25 2.27344 7.60547 2.875 6.875 2.875H4.125C3.35156 2.875 2.75 3.51953 2.75 4.25V15.25C2.75 16.0234 3.35156 16.625 4.125 16.625H6.875ZM21.5703 8.80469C22.1289 9.32031 22.1289 10.2227 21.5703 10.7383L16.0703 16.2383C15.5547 16.7969 14.6523 16.7969 14.1367 16.2383C13.5781 15.7227 13.5781 14.8203 14.1367 14.3047L17.2734 11.125H8.25C7.47656 11.125 6.875 10.5234 6.875 9.75C6.875 9.01953 7.47656 8.375 8.25 8.375H17.2734L14.1367 5.23828C13.5781 4.72266 13.5781 3.82031 14.1367 3.30469C14.6523 2.74609 15.5547 2.74609 16.0703 3.30469L21.5703 8.80469Z"
        fill="currentColor"
      />
    </svg>
  );
}
