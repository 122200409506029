import { strValOrUndef } from "@paroi/data-formatters-lib";
import type { Obj } from "@paroicms/anywhere-lib";
import { parse } from "papaparse";
import { Button } from "primereact/button";
import {
  FileUpload,
  type FileUploadHandlerEvent,
  type FileUploadHeaderTemplateOptions,
  type ItemTemplateOptions,
} from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Suspense, lazy, useRef, useState } from "react";
import { appLog } from "../../../../../context";
import type { ModalDialogProps } from "../../../../../global-tools/dialog-system";
import { showToast } from "../../../../../global-tools/toast-system";
import { createAcceptTypeFilter } from "../../../../../helpers/accept-type-filter";
import type { SchemaField, SchemaFieldTypes } from "../../../../../helpers/format-databases";
import Spinner from "../../../../utils/Spinner/Spinner";
import "./UploadFileDialog.scss";

const SchemaFieldDataTable = lazy(() => import("../../SchemaFieldDataTable/SchemaFieldDataTable"));

export interface UploadFileDialogProps extends ModalDialogProps<void> {
  closeDialog: () => void;
  parameter: UploadFileDialogParameter;
}

export interface UploadFileDialogParameter {
  onValidate(values: SchemaField[]): void;
  schemaFieldTypes: SchemaFieldTypes;
}

export default function makeUploadFileDialog({
  closeDialog,
  parameter: { onValidate, schemaFieldTypes },
}: UploadFileDialogProps) {
  const limitSize = 10_000_000; // 10 Mo
  const accept = "text/csv";
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);
  const [fields, setFields] = useState<SchemaField[]>([]);

  const addFields = () => {
    if (fields.length === 0) return;
    onValidate(fields);
    closeDialog();
  };
  const onFileChange = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];
    const acceptTypeFilter = createAcceptTypeFilter(accept);
    if (!acceptTypeFilter(file.type)) {
      fileUploadRef.current?.clear();
      showToast("Fichier invalide", {
        severity: "warn",
      });
      return;
    }
    setTotalSize(file.size);
    try {
      const parsedFields = await parseCsvFile(file, schemaFieldTypes);
      setFields([...parsedFields]);
      showToast("Fichier Uploadé", { severity: "success" });
    } catch (error) {
      appLog.error(error);
      showToast("Fichier vide ou non valide", { severity: "error" });
    }
  };
  const onTemplateRemove = (file: File, callback: () => void) => {
    setTotalSize(totalSize - file.size);
    callback();
    setFields([]);
  };
  const onTemplateClear = () => {
    setTotalSize(0);
    setFields([]);
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;
    const progressBarVal = (totalSize * 100) / limitSize;
    const formatedValue = fileUploadRef?.current
      ? fileUploadRef.current.formatSize(totalSize)
      : "0 B";
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>
            {formatedValue} / {limitSize / 1_000_000} Mo
          </span>
          <ProgressBar
            value={progressBarVal}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          />
        </div>
      </div>
    );
  };
  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img alt={file.name} role="presentation" src={(file as any).objectURL} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          style={{ width: "30px", height: "10px" }}
          onClick={(e) => onTemplateRemove(file, () => props.onRemove(e))}
        />
      </div>
    );
  };
  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        />
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Glissez-déposez votre fichier ici
        </span>
      </div>
    );
  };
  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  const content = (
    <div className="m-0 UploadFileDialog-content">
      <FileUpload
        ref={fileUploadRef}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        auto
        accept={accept}
        maxFileSize={limitSize}
        customUpload
        uploadHandler={onFileChange}
        chooseLabel="Choisir un fichier"
      />

      <Suspense fallback={<Spinner />}>
        <SchemaFieldDataTable
          className="mt-4"
          schemaFields={fields}
          schemaFieldTypes={schemaFieldTypes}
        />
      </Suspense>

      <div className="mt-6 flex justify-content-between">
        <Button className="Btn secondary" label="Annuler" onClick={closeDialog} />
        <Button
          className="Btn primary"
          label="Valider"
          onClick={addFields}
          disabled={fields.length === 0}
        />
      </div>
    </div>
  );

  return {
    header: (
      <div className="text-center underline" style={{ fontSize: "22px", fontWeight: "400" }}>
        Uploader un fichier CSV
      </div>
    ),
    content,
  };
}

function parseCsvFile(file: File, schemaFieldTypes: SchemaFieldTypes): Promise<SchemaField[]> {
  return new Promise((resolve, reject) => {
    parse(file, {
      header: true,
      skipEmptyLines: true,
      error: (error) => reject(error),
      complete: (results) => {
        const defaultSchemaType = schemaFieldTypes.keys().next().value;
        if (!defaultSchemaType) {
          throw new Error("Empty schema field types");
        }
        const fields: Map<string, SchemaField> = new Map();
        const data = results.data;
        const dataFields = results.meta.fields;
        if (data.length === 0 && (!dataFields || dataFields.length === 0)) {
          reject("Fichier vide ou non valide");
          return;
        }
        if (data.length > 0) {
          const row = data[0] as Obj;
          for (const [columnName, type] of Object.entries(row)) {
            const formatedType = strValOrUndef(type);
            const normalizedStr = normalizeStr(columnName);
            if (!isValidColumnName(normalizedStr) || fields.has(normalizedStr)) {
              continue;
            }
            fields.set(normalizedStr, {
              label: columnName,
              name: normalizedStr,
              required: false,
              type:
                formatedType && isValidSchemaFieldType(formatedType, schemaFieldTypes)
                  ? formatedType
                  : schemaFieldTypes.has("string")
                    ? "string"
                    : defaultSchemaType,
            });
          }
        }

        if (dataFields && data.length < dataFields.length) {
          for (let i = 0; i < dataFields.length; ++i) {
            const field = dataFields[i];
            if (i < data.length) continue;
            const normalizedStr = normalizeStr(field);
            if (!isValidColumnName(normalizedStr) || fields.has(normalizedStr)) {
              continue;
            }
            fields.set(normalizedStr, {
              label: field,
              name: normalizedStr,
              required: false,
              type: schemaFieldTypes.has("string") ? "string" : defaultSchemaType,
            });
          }
        }

        resolve(Array.from(fields, ([, value]) => value));
      },
    });
  });
}

function isValidSchemaFieldType(type: string, schemaFieldTypes: SchemaFieldTypes): boolean {
  if (schemaFieldTypes.has(type)) return true;
  return false;
}

function normalizeStr(str: string): string {
  return (
    str
      .trim()
      .normalize("NFD")
      // biome-ignore lint/suspicious/noMisleadingCharacterClass: not sure
      .replace(/[\u0300-\u036f]/g, "") // remove diacritics
      .replace(/[^0-9a-zA-Z]/g, "")
      .toLowerCase()
  );
}

function isValidColumnName(field: string): boolean {
  const result = field.match(/^[a-zA-Z]+[0-9]*$/);
  return !!result;
}
