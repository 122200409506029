import { Link } from "react-router-dom";

import "./ReferenceCard.scss";

export default function ReferenceCard({
  label,
  fields,
  rows,
  date,
  route,
}: { route: string; date: string; label: string; fields: number; rows: number }) {
  return (
    <Link to={route} className="ReferenceCard Card">
      <span className="ReferenceCard-header">{label}</span>

      <div className="ReferenceCard-content">
        <div className="ReferenceCard-left">
          <p className="ReferenceCard-fields">
            <b>{fields}</b> <small className="ReferenceCard-smallText">Champs</small>
          </p>
          <p className="ReferenceCard-date">
            Créée le: <b>{date}</b>
          </p>
        </div>

        <div className="ReferenceCard-right">
          <b style={{ display: "block" }}>{rows}</b>{" "}
          <small className="ReferenceCard-smallText">Ligne{rows > 1 ? "s" : ""}</small>
        </div>
      </div>
    </Link>
  );
}
