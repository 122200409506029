import { useWrapAsync } from "@paroicms/front-app-log";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

import type { Nullable } from "primereact/ts-helpers";
import { Suspense, lazy, useEffect, useState } from "react";
import { fetchDatabases } from "../../../api-requests/databases";
import { type RequestError, fetchStatisticsByReference } from "../../../api-requests/statistics";
import { appLog } from "../../../context";
import { createDayJs, dayJsFormat } from "../../../global-tools/dayjs.helpers";
import type { Statistics } from "../../../helpers/format-statistics";
import { getReferences } from "../../../helpers/get-references";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import Navbar from "../../parts/Navbar/Navbar";
import OurDropdown from "../../utils/OurDropdown/OurDropdown";
import Spinner from "../../utils/Spinner/Spinner";
import "./StatisticsScreen.scss";

const StatisticsPart = lazy(() => import("./StatisticsPart/StatisticsPart"));

export default function StatisticsScreen() {
  const wrapAsync = useWrapAsync();
  const [referenceId, setReferenceId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [selectedReferenceId, setSelectedReferenceId] = useState<number>();
  const references = useAppStore((state) => state.references);
  const setReferences = useAppStore((state) => state.setReferences);
  const [statistics, setStatistics] = useState<Statistics | RequestError>();
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null);

  const handleStatisticsByReference = wrapAsync(async () => {
    if (selectedReferenceId === undefined) return;
    setLoading(true);
    setReferenceId(selectedReferenceId);
    const options = dates
      ? {
          startedAt: dates[0]
            ? dayJsFormat(dates[0], "YYYY-MM-DD")
            : dayJsFormat(new Date(), "YYYY-MM-DD"),
          endedAt: dates[1]
            ? dayJsFormat(dates[1], "YYYY-MM-DD")
            : dayJsFormat(createDayJs(new Date()).add(1, "year").toISOString(), "YYYY-MM-DD"),
        }
      : undefined;
    try {
      setStatistics(await fetchStatisticsByReference(selectedReferenceId, options));
    } catch (error) {
      appLog.error(error);
      setStatistics({
        error: "An error occurs when trying to fetch statistics",
      });
    }
    setLoading(false);
  });

  useEffect(
    wrapAsync(async () => {
      if (references) return;
      await apiRequestWrapper(async () => {
        const result = await fetchDatabases();
        setReferences(getReferences(result.data));
      });
    }),
    [references],
  );

  if (!references) return <Spinner />;
  return (
    <div>
      <Navbar pageTitle="Statistiques" />

      <div className="StatisticsScreen Container">
        <div className="Container-content">
          <form
            style={{
              display: "flex",
              gap: "36px",
              alignItems: "end",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleStatisticsByReference();
            }}
          >
            <label className="Field w-full">
              <span className="Field-label">Sélectionner un référentiel</span>
              <OurDropdown<number>
                selectedOption={selectedReferenceId}
                options={references.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
                onChange={(value) => {
                  setSelectedReferenceId(value);
                }}
                filter
              />
            </label>

            <label className="Field w-full">
              <span className="Field-label">Sélectionner la période</span>
              <Calendar
                value={dates}
                onChange={(e) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                className="w-full"
                showIcon
                placeholder="Filtre par Date"
              />
            </label>

            <div>
              <Button
                className="Btn lg primary"
                label="Valider"
                disabled={selectedReferenceId === undefined}
              />
            </div>
          </form>
          <hr className="StatisticsScreen-divider" />
          {loading ? (
            <div style={{ marginTop: "85px" }}>
              <Spinner />
            </div>
          ) : (
            referenceId && (
              <div style={{ marginTop: "85px" }}>
                <Suspense fallback={<Spinner />}>
                  <StatisticsPart referenceId={referenceId} statistics={statistics} />
                </Suspense>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
