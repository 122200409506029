import * as Sentry from "@sentry/react";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import AppSidebar from "../components/parts/AppSidebar/AppSidebar";
import Footer from "../components/parts/Footer/Footer";
import ErrorNotFoundScreen from "../components/screens/ErrorNotFoundScreen/ErrorNotFoundScreen";
import Spinner from "../components/utils/Spinner/Spinner";
import { eventBus } from "../global-tools/event-bus";
import { useAppStore } from "../store/app.store";

import "./AppLayout.scss";

export default function AuthenticatedApp() {
  const location = useLocation();
  const [show404, setShow404] = useState(false);
  const appLayout = useAppStore((state) => state.appLayout);
  const [isSidebarVisible, setSidebarVisible] = useState(appLayout === "desktop");

  useEffect(() => {
    const handler = () => setShow404(true);
    eventBus.on("404", handler);
    setSidebarVisible(appLayout === "desktop");

    return () => {
      eventBus.off("404", handler);
    };
  }, []);

  useEffect(() => {
    setSidebarVisible(appLayout === "desktop");
  }, [appLayout === "desktop"]);

  useEffect(() => setShow404(false), [location]);

  if (show404) Sentry.captureException("404 error throw by evenBus");
  if (isSidebarVisible === undefined) return <Spinner />;

  const mainContent = (
    <main className="AppLayout-main">
      {show404 ? (
        <ErrorNotFoundScreen />
      ) : (
        <>
          <Outlet /> <Footer isMobileLayout={appLayout === "mobile"} />
        </>
      )}
    </main>
  );

  return appLayout === "desktop" ? (
    <div className="AppLayout">
      <aside className="AppLayout-side">
        <AppSidebar setVisible={setSidebarVisible} visible={isSidebarVisible} />
      </aside>
      {mainContent}
    </div>
  ) : (
    <div className="MobileLayout">
      <Button
        className="p-button-primary MobileLayout-burgerBtn"
        icon="pi pi-bars"
        onClick={() => setSidebarVisible(true)}
      />
      <AppSidebar setVisible={setSidebarVisible} visible={isSidebarVisible} asDrawer={true} />
      {mainContent}
    </div>
  );
}
