import { Toast, type ToastMessage } from "primereact/toast";
import { type RefObject, useRef } from "react";

let appToast: RefObject<Toast> | undefined;

export interface ToastSystemOptions {
  severity?: ToastMessage["severity"];
  sticky?: boolean;
}

export function showToast(message: string, options?: ToastSystemOptions): void;
export function showToast(message: ToastMessage): void;
export function showToast(message: ToastMessage | string, options?: ToastSystemOptions): void {
  let tm: ToastMessage;
  if (typeof message === "string") {
    const severity = options?.severity;
    const sticky = options?.sticky;
    tm = {
      summary: severity === undefined ? undefined : severity,
      detail: message,
      severity,
      sticky,
    };
  } else {
    tm = message;
  }
  appToast?.current?.show(tm);
}

export function clearToast() {
  appToast?.current?.clear();
}

export function ToastSystem() {
  const toast = useRef<Toast>(null);
  appToast = toast;
  return <Toast ref={toast} />;
}
