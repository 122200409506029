import { useWrapAsync } from "@paroicms/front-app-log";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { type ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchDatabases } from "../../../api-requests/databases";
import { dayJsFormat } from "../../../global-tools/dayjs.helpers";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import PlusIcon from "../../icons/PlusIcon";
import Navbar from "../../parts/Navbar/Navbar";
import NoDataMessage from "../../utils/NoDataMessage/NoDataMessage";
import Spinner from "../../utils/Spinner/Spinner";
import DatabaseCard from "./DatabaseCard/DatabaseCard";
import "./DatabaseListScreen.scss";

export default function DatabaseListScreen() {
  const wrapAsync = useWrapAsync();
  const [searchValue, setSearchValue] = useState("");
  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const databases = useAppStore((state) => state.databases);
  const setDatabases = useAppStore((state) => state.setDatabases);

  useEffect(
    wrapAsync(async () => {
      if (databases) return;
      await apiRequestWrapper(async () => {
        const result = await fetchDatabases();
        setDatabases(result.data);
      });
    }),
    [databases],
  );

  const referenceListView =
    databases === undefined ? (
      <Spinner />
    ) : databases.length > 0 ? (
      <div className="DatabaseListScreen-cardList">
        {databases.map((reference) => (
          <DatabaseCard
            key={reference.id}
            route={`/databases/${reference.id}/view`}
            label={reference.name}
            fields={reference.schemaNames.length}
            rows={reference.lineNumber}
            date={dayJsFormat(reference.createdAt, "DD/MM/YYYY")}
            status={reference.databaseGenerated ? "validate" : "in-progress"}
          />
        ))}
      </div>
    ) : (
      <NoDataMessage />
    );

  return (
    <div>
      <Navbar pageTitle="Liste des bases de données" />

      <div className="DatabaseListScreen Container">
        <div className="Container-content">
          <div className="DatabaseListScreen-header">
            <IconField iconPosition="left" className="InputWithIcon lg">
              <InputIcon className="pi pi-search" />
              <InputText value={searchValue} onChange={onSearchChange} placeholder="Recherche…" />
            </IconField>

            <Link to="/databases/create" className="Btn primary icon link">
              Nouveau <PlusIcon />
            </Link>
          </div>

          {referenceListView}
        </div>
      </div>
    </div>
  );
}
