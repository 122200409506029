import "./Footer.scss";

export default function Footer({ isMobileLayout }: { isMobileLayout: boolean }) {
  return (
    <footer className="Footer">
      <p className="Footer-content" style={{ marginRight: isMobileLayout ? "0" : "322px" }}>
        Plateforme Nationale d’Interopérabilité (PNI) © ASIN - 2024
      </p>
    </footer>
  );
}
