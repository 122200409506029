import { useWrapAsync } from "@paroicms/front-app-log";
import { Button } from "primereact/button";
import { useState } from "react";
import type { ModalDialogProps } from "../../../global-tools/dialog-system";

export interface ConfirmDialogProps extends ModalDialogProps<void> {
  closeDialog: () => void;
  parameter: {
    onConfirm(): Promise<void> | void;
    warningMessage: string | JSX.Element;
    header?: string;
    label?: string;
  };
}

export default function makeConfirmDialog({
  closeDialog,
  parameter: {
    onConfirm,
    warningMessage,
    header = "Confirmer la suppression",
    label = "Supprimer",
  },
}: ConfirmDialogProps) {
  const wrapAsync = useWrapAsync();
  const [loading, setLoading] = useState(false);

  const content = (
    <div>
      <p className="mb-4">{warningMessage}</p>
    </div>
  );

  const footer = (
    <div className="flex justify-content-between">
      <Button className="Btn secondary" label="Annuler" onClick={() => closeDialog()} />
      <Button
        className="Btn danger"
        label={label}
        onClick={wrapAsync(async () => {
          setLoading(true);
          await onConfirm();
          setLoading(false);
          closeDialog();
        })}
        loading={loading}
      />
    </div>
  );

  return { header, content, footer };
}
