import { listVal, nbVal, strVal, strValOrUndef } from "@paroi/data-formatters-lib";

export interface Statistics {
  kpis: KpiStat[];
  diagramData?: Map<string, number>;
}

export interface KpiStat {
  name?: string;
  property: string;
  value?: string;
  data: {
    count: number;
    updatedAt?: string;
  };
}

export function formatStatistics(data: any): Statistics {
  let diagramData: Map<string, number> | undefined;
  if (data.histogram_data && typeof data.histogram_data === "object") {
    for (const [key, val] of Object.entries(data.histogram_data)) {
      if (!diagramData) diagramData = new Map();
      diagramData.set(key, nbVal(val));
    }
  }
  return {
    kpis: listVal(data.kpis, formatKpiStat),
    diagramData,
  };
}

export function formatKpiStat(data: any): KpiStat {
  return {
    name: strValOrUndef(data.name),
    property: strVal(data.porperty),
    value: strValOrUndef(data.value),
    data: {
      count: nbVal(data.data.count),
      updatedAt: strValOrUndef(data.data.updated_at),
    },
  };
}
