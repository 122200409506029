import { create } from "zustand";
import type { Database, Reference, SchemaFieldTypes } from "../helpers/format-databases";
import type { ReferenceDatum } from "../helpers/format-reference-data";
import type { Settings } from "../helpers/format-settings";
import type { User } from "../helpers/format-users";
import type { AppLayout } from "./store-types";

export interface AppStore {
  appLayout: AppLayout;
  setAppLayout: (appLayout: AppLayout) => void;

  isAuthenticated: boolean;
  setIsAuthenticated: (authenticated: boolean) => void;

  databases?: Database[];
  setDatabases: (databases?: Database[]) => void;

  references?: Reference[];
  setReferences: (references?: Reference[]) => void;

  referenceDataById: Map<number, ReferenceDatum[] | undefined>;
  setReferenceDataById: (databaseDataById: Map<number, ReferenceDatum[] | undefined>) => void;

  users?: User[];
  setUsers: (users?: User[]) => void;

  authUser?: User;
  setAuthUser: (authUser: User | undefined) => void;

  settings?: Settings;
  setSettings(settings?: Settings): void;

  schemaFieldTypes?: SchemaFieldTypes;
  setSchemaFieldTypes: (schemaFieldTypes?: SchemaFieldTypes) => void;
}

export const useAppStore = create<AppStore>()((set) => ({
  appLayout: "desktop",
  setAppLayout: (appLayout) => set({ appLayout }),

  isAuthenticated: false,
  setIsAuthenticated: (authenticated) => set({ isAuthenticated: authenticated }),

  setDatabases: (databases) => set({ databases }),

  setReferences: (references) => set({ references }),

  referenceDataById: new Map(),
  setReferenceDataById: (referenceDataById) => set({ referenceDataById }),

  setUsers: (users) => set({ users }),

  setAuthUser: (authUser) => set({ authUser }),

  setSettings: (settings) => set({ settings }),

  setSchemaFieldTypes: (schemaFieldTypes) => set({ schemaFieldTypes }),
}));
