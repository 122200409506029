export default function JournalsIcon({ classNames }: { classNames: string }) {
  return (
    <svg
      className={classNames}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.375 0.125C2.10547 0.125 2.75 0.769531 2.75 1.5V15.9375C2.75 16.3242 3.05078 16.625 3.4375 16.625H20.625C21.3555 16.625 22 17.2695 22 18C22 18.7734 21.3555 19.375 20.625 19.375H3.4375C1.50391 19.375 0 17.8711 0 15.9375V1.5C0 0.769531 0.601562 0.125 1.375 0.125ZM5.5 4.25C5.5 3.51953 6.10156 2.875 6.875 2.875H15.125C15.8555 2.875 16.5 3.51953 16.5 4.25C16.5 5.02344 15.8555 5.625 15.125 5.625H6.875C6.10156 5.625 5.5 5.02344 5.5 4.25ZM12.375 7C13.1055 7 13.75 7.64453 13.75 8.375C13.75 9.14844 13.1055 9.75 12.375 9.75H6.875C6.10156 9.75 5.5 9.14844 5.5 8.375C5.5 7.64453 6.10156 7 6.875 7H12.375ZM17.875 11.125C18.6055 11.125 19.25 11.7695 19.25 12.5C19.25 13.2734 18.6055 13.875 17.875 13.875H6.875C6.10156 13.875 5.5 13.2734 5.5 12.5C5.5 11.7695 6.10156 11.125 6.875 11.125H17.875Z"
        fill="currentColor"
      />
    </svg>
  );
}
