import ArrowDown from "../../../assets/icons/arrow-down.svg";
import Avatar from "../../../assets/icons/avatar.svg";
import { useAppStore } from "../../../store/app.store";
import { useAuthenticatedUser } from "../../../store/store-hooks";
import NotificationIcon from "../../icons/NotificationIcon";
import "./Navbar.scss";

const showDropdown = false;
const showNotifIcon = false;

export default function Navbar({ pageTitle }: { pageTitle: string }) {
  const appLayout = useAppStore((state) => state.appLayout);
  const authUser = useAuthenticatedUser();

  return (
    <div className="Navbar" style={{ marginLeft: appLayout === "mobile" ? "59px" : "0" }}>
      <div className="Navbar-leftSide">
        <span>{pageTitle}</span>
      </div>
      <div className="Navbar-rightSide">
        {showNotifIcon && <NotificationIcon hasNotification={true} />}
        <div className="Navbar-userDropdown">
          <img src={Avatar} alt="" />
          <div className="Navbar-userDetails">
            <span className="Navbar-userFullname">{authUser.fullname}</span>
            <small className="Navbar-userRole">
              {authUser.roles.map((r) => (
                <span key={r.id} className="mr-2">
                  {r.name}
                </span>
              ))}
            </small>
          </div>
          {showDropdown && <img src={ArrowDown} style={{ marginLeft: "34px" }} alt="" />}
        </div>
      </div>
    </div>
  );
}
