import { Link } from "react-router-dom";
import "./ErrorNotFoundScreen.scss";

export default function ErrorNotFoundScreen() {
  return (
    <div className="ErrorNotFoundScreen">
      <h1>Oops!</h1>
      <p>Ressource non trouvée</p>

      <Link to="/" className="Btn lg primary">
        Retourner sur la page d'accueil
      </Link>
    </div>
  );
}
